.editInfo {
    // background-color: rgba(32, 25, 87, 1);
    width: 700px;
    min-height: 400px;
    // display: flex;
    color: white;
    // border-radius: 20px;

    .heading {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px;

        .text1 {
            font-size: 25px;
            font-weight: 400;
        }
    }

    .border {
        border-bottom: 1px solid rgba(89, 91, 104, 1);
        margin-bottom: 20px;
    }

    .fieldBox {
        padding: 20px 20px 0px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .DFpd {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
    }


    .newClientDiv {
        padding: 20px 30px;

        .row1 {
            display: flex;
            align-items: center;

            .inputWithLevel {
                width: 50%;
                margin-bottom: 20px;
                margin-right: 10px;
                height: 80px;
                // .text {
                //     color: rgba(102, 107, 138, 1);
                // }
            }

        }

        .text {
            color: rgba(102, 107, 138, 1);
        }

        .btnDiv {
            margin: 20px 10px;
            display: flex;
            justify-content: flex-end;
        }
    }

}

.inputWithLevel {
    width: 48%;
    margin-bottom: 20px;

    .text {
        color: rgba(169, 168, 168, 1);
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.inputWithLevel2 {
    width: 100%;
    margin-bottom: 20px;

    .text {
        color: rgba(169, 168, 168, 1);
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.addLeadTextArea::-webkit-input-placeholder {
    color: #9ca1c7;
}

.addLeadTextArea:-moz-placeholder {
    /* Firefox 18- */
    color: #413B70;
}

.addLeadTextArea::-moz-placeholder {
    /* Firefox 19+ */
    color: #413B70;
}

.addLeadTextArea:-ms-input-placeholder {
    color: #413B70;
}

.addLeadTextArea::placeholder {
    color: #413B70;
}