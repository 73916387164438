$avatar-size: 30px;

// WATCH VIDEO EXPLANATION: https://youtu.be/aQ2inFyLguo
.avatar {
  width: $avatar-size;
  height: $avatar-size;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background-color: #2c303a;
  // border: 2px solid #2c303a;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.avatar-group {
  display: flex;

  &.rtl {
    direction: rtl;

    .avatar:hover:not(:last-of-type) {
      transform: translate(10px);
    }
  }

  .avatar {
    margin-left: -10px;
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover:not(:last-of-type) {
      transform: translate(-10px);
    }
  }

  .hidden-avatars {
    width: $avatar-size;
    height: $avatar-size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    // margin-right: 23px;
    background-color: var(--progressBarColor);
    color: #fff;
    font-size: 11px;
    padding-right: 2px;
    font-family: "Lato-Bold";
  }
}

.showBottom {
  transform: translate(0%, 53%);
}

.showTop {
  transform: translate(0%, -53%);
}

.extraShow {
  position: absolute;
  background-color: var(--popUpColor);
  // padding: 5px;
  border-radius: 5px;
  min-width: 250px;
  z-index: 999;

  .rowData {
    border-bottom: 1px solid var(--divider);
    padding: 5px 10px;
    cursor: default;
  }

  .borderNone {
    border: none;
  }
  .rowData:hover {
    background-color: var(--divider);
  }

  .member_name {
    flex: 1 1 0%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
}
