.welcome_wrapper {
  width: 100%;
  height: 100vh;
  background: #0e0841;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  color: var(--defaultWhite);
  font-family: "Comfortaa-Medium";
  overflow: hidden;
}
.welcome_wrapper > .welcome_card {
  width: 70%;
  margin: auto auto;
  border-radius: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.welcome_wrapper > .welcome_card > .welcome_card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.welcome_wrapper > .welcome_card > .welcome_card__content > .h4 {
  color: #fff;
  margin-top: 3rem;
  font-size: 2.8rem;
}

.welcome_wrapper > .welcome_card > .welcome_card__content > .h5 {
  margin-top: 1.5rem;
  color: #9d9da6;
}
.welcome_wrapper > .welcome_card > .welcome_card__content > img {
  width: 6rem;
  height: 6rem;
  object-fit: none;
}
.welcome__button_container {
  margin-top: 1rem;
  display: flex;

  justify-content: center;
}

.welcome__button_container > a > * {
  margin-top: 1rem;
  min-width: 10rem;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 1rem;
  border: solid 1px var(--main);
  /* background: var(--main); */
  background: white;
  height: 2.7rem;
  color: var(--main);
  cursor: pointer;
}

.welcome__button_container > a > button:hover {
  background: var(--main);
  color: white;
}

.welcome__button_container > a:nth-child(2) {
  margin-left: 1rem;
}

.welcomeButton {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  align-items: center;
  width: 50%;
}

.welcomeButton > * {
  flex-basis: 100%;
}

.welcome_wrapper::before {
  background: "url(src/assets/Images/shape1.png)";
  content: "";
}

.shape1 {
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  max-height: 250px;
}

.shape2 {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  max-height: 250px;
}

.text_area {
  margin: 1em 0;
}

.text_area > * {
  margin: 0.5em 0;
}

/* background animation */

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Exo", sans-serif;
}

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: var(--primary);
  /* background-color: #4e54c8; */
  animation: animate 15s linear infinite;
  bottom: -150px;
  left: 5%;
}

.circles li:nth-child(1) {
  left: 18%;

  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 10%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 8 5%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 50px;
  height: 50px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 85%;
  width: 50px;
  height: 50px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 18%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.trans {
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  color: var(--green);
}

.opacity {
  opacity: 0;
  animation: opacity 4s linear infinite;
}

.opacity-0 {
  animation: opacity 0.3s linear;
  /* opacity: 0; */
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
