.taskKanbanContainer {
  background-color: var(--milestoneCard);
  border-left: 2px solid burlywood;
  padding: 10px;
  width: 100%;
  /* min-height: 1000px; */
  /* max-height: 100px;
  overflow: hidden; */
}

.tasktitle {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
  height: 65%;
}
