.rowItemSection {
    border: 1px solid var(--progressBarColor);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    margin-bottom: 5px;

    .child1 {
        width: 110%;
        padding: 0px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 100px;

        .img {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

    }

    .child2 {
        // background: green;
        width: 100%;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
    }

    .child3 {
        // background: gold;
        width: 150%;
        padding: 0px 10px 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;

        .text {
            margin-right: 3px;
            overflow: hidden;
            max-height: 40px;
            max-width: 140px;
        }

        .icon {
            width: 15px;
            height: 15px;
            cursor: pointer;

        }
    }

    .child4 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;

        .text {
            padding: 5px 13px;
            border-radius: 20px;
            font-size: 11px;
        }
    }

    .child5 {
        // background: pink;
        width: 100%;
        /* padding: 0px 10px; */
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        min-width: 100px;

        .crossTickSection {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }


        .profileDSection {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
        }

        .imgProfile {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            border: 2px solid var(--primary);
        }

        .icon {
            width: 13px;
            height: 13px;
            cursor: pointer;
        }
    }


}