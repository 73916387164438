    .tableMainDiv {
        margin: 20px 0px;

        .sideTopBottomTableBoarder {
            border: 1px solid var(--newBlueLight);
            border-left: 5px solid var(--primary);
            border-top-left-radius: 10px;
        }

        .dateTableHeader {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dateBG {
                background: var(--projectCardBg);
                padding: 10px 15px;
                border-radius: 5px;
                margin-bottom: 15px;
                // color: ;
            }
        }
    }