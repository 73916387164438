.headingSection {
    display: flex;
    border-radius: 5px;
    background: var(--progressBarColor);
    margin-bottom: 10px;
    padding: 8px;
    margin: 10px 5px;

    .child1 {
        width: 110%;
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;


    }

    .child2 {
        // background: green;
        width: 100%;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .child3 {
        // background: gold;
        width: 150%;
        padding: 0px 10px 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
            margin-right: 8px;
        }

    }

    .child4 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
            padding: 5px 13px;
            border-radius: 20px;
            font-size: 11px;
        }
    }

    .child5 {
        // background: pink;
        width: 100%;
        /* padding: 0px 10px; */
        display: flex;
        justify-content: center;
        align-items: center;

    }
}