.invoicePaymentFilterSec {
    // background: red;

    position: absolute;
    right: 15px;
    top: 150px;
    z-index: 99;
    max-width: 920px;
    min-width: 300px;
    border-radius: 5px;


    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 8px;
        background: rgb(53, 53, 129);

        .text1 {
            color: rgb(255, 255, 255);
            font-size: 14px;
            ;
        }

        .text2 {
            color: rgba(255, 255, 255, 0.5);
            font-size: 13px;
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
            border-width: 1
        }
    }

    .filterSection {
        background-color: #292F58;
        padding: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .df {
        display: flex;
        align-items: center;
    }

}