.inputSelectBox {
    //
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    //
    background: #413B70;
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    width: 32%;
    border-radius: 6px;

    .inputBox {
        border: none;
        background: #413B70;
        color: var(--primary);
    }

    .inputBox::placeholder {
        color: #A9A8A8;
    }

    .text {
        font-family: 'Lato';
        color: #A9A8A8;
    }

    .inputBox,
    textarea {
        width: 500px !important;
        color: white;
    }

    .inputBox:focus {
        outline: 0;
    }

    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    input[type="date"]::-webkit-input-placeholder {
        visibility: hidden !important;
    }

}