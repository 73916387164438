.checkBoxSection {

    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    // cursor: pointer;
    ;

    .checkBoxSize {
        padding: 3px;
        background: var(--checkBoxBlue);
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        .icon {
            width: 12px;
            height: 12px;
            stroke: black;
        }
    }

    .checkBoxBlank {
        width: 15px;
        height: 15px;
        border: 1.5px solid white;
        border-radius: 3px;
        margin-right: 13px;

    }

    .text {
        font-family: Lato;
        font-style: Regular;
        font-size: 17px;

    }

    .outerCircle {

        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;


        .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #1976D2;
        }
    }




}

.ml {
    margin-left: 30px;
}