.additionalInfo {
    // border: 1px solid gray;
    margin: 15px;
    overflow-y: auto;

    .box2 {
        background-color: rgba(32, 25, 87, 1);
        border-radius: 10px;
        width: 100%;
        padding: 20px;
        margin-bottom: 10px;

        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .textColor {
                color:
                    rgba(250, 188, 42, 1);
                font-size: 16px;
                font-weight: 400;
            }

            .btnDiv {
                display: flex;

            }

            .btn {
                background-color: rgba(250, 188, 42, 1);
                color: black;
                height: 30px;
            }
        }

        .clientName {
            font-size: 25px;
            margin-bottom: 20px;
        }

    }

    .lightText {
        color: rgba(102, 107, 138, 1);
        margin-bottom: 5px;
    }

    .dfm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
    border-radius: 40px;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}