.eodDropDown {
  background: #363d68;
  width: 100%;
  border-radius: 7px;
  cursor: pointer;

  .df {
    display: flex;
    align-items: center;

    .text {
      padding: 10px;
      color: gray;
    }
  }
}

.durationDiv {
  background: #29304c;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  z-index: 10;
  // position: absolute;
  // bottom: 102px;
  right: -10px;
  display: flex;
  justify-content: space-around;

  .hourDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 281px;
    overflow: auto;

    .hourText {
      background: #363d68;
      padding: 5px 10px;
      margin: 0px 10px;
      border-radius: 6px;
    }

    .hourlist {
      // background: #363D68;
    }
  }

  .durationList {
    cursor: pointer;
    padding: 5px 10px;
    top: calc(100% - 280px);
    /*100% - height*/
    transition: all 0.3s ease;
    will-change: top;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .durationList:hover {
    background: var(--blakish);
    width: 100%;
    text-align: center;
  }
}
