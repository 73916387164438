/* .addLead {
  padding: 1em;
}

.inputArea {
  display: flex;
  flex-wrap: wrap;
}

.inputArea > * {
  min-width: 50% !important;
  flex-grow: 1;
  padding: 0.5em !important;
}

.inputArea > * > div {
  border: 1px solid var(--divider) !important;
}

.inputArea input {
  padding: 0.6em;
}

.inputArea div {
}

.inputArea input::placeholder {
  color: var(--defaultWhite) !important;
}

.inputArea textarea::placeholder {
  color: var(--defaultWhite) !important;
}

.btnArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnArea > * {
  margin: 1.5em !important;
  margin-bottom: 0 !important;
}

.select {
  border-radius: 4px;
  height: 100%;
}

.select p {
  padding-left: 0.6em;
}

.selected p {
  color: var(--defaultWhite) !important;
  opacity: 0.5 !important;
  padding-left: 0.6em;
}

.select::before {
  border-bottom: none !important;
}

.addLead svg {
  color: var(--defaultWhite) !important;
}

.datePicker {
  margin-top: 0 !important;
  border-radius: 14px !important;
  margin: 0 !important;
}

.datePicker div::before {
  border: 0px solid !important;
} */

/* .lable {
  color: var(--defaultWhite) !important;
  opacity: 0.5 !important;
}

label {
}

label + div {
  margin: 0 !important;
}

.select div {
} */

/*************Add Lead*************/

.addleadpopup {
  width: 500px !important;
  padding: 30px 25px 30px;
  background: #2f3453 !important;
  color: #fff !important;
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 0px 0px 0px 0px #000 !important;
}
.addleadContent form input {
  width: 100%;
  height: 44px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 24px;
  border-color: #363d72;
  // color: #9ca1c7;
  color:"#FFF"
}
.addleadContent form select {
  width: 100%;
  height: 44px;
  margin-bottom: 15px;
  padding: 13px;
  border-radius: 24px;
  border-color: #363d72;
  color: #9ca1c7;
  background: var(--blakish);
}
.addleadContent form input::placeholder {
  color: #9ca1c7;
}
.inlineInput {
  display: flex;
  width: 100%;
}
.inputLeft {
  padding-right: 15px;
  flex: 1;
}
.inputRight{
  flex: 1;
}
.addleadHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #666c8f;
}
.addleadContent {
  margin-top: 30px;
}
  .addleadFooter{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .addleadFooter .btn1 {
  background:transparent;
  border: 1px solid var(--primary) !important;
  border: none;
  height: 30px;
  width: 100px;
  color: #fff;
  font-size: 14px;
  border-radius: 16px;
}
.addleadFooter .btn2 {
  background: var(--primary);
  border: 1px solid var(--primary) !important;
  border: none;
  height: 30px;
  width: 100px;
  color: #fff;
  font-size: 14px;
  border-radius: 16px;
}


.addLeadAutoComplete .MuiAutocomplete-inputRoot{
margin: 0px !important;
padding: 0px !important;
}
.inputLeft .inputBox_addLead{
  width: 100%;
  height: 44px;
  margin-bottom: 15px;
  padding-left: 15px;
  border-radius: 24px;
  border-color: #363d72;
  background: var(--blakish);
  // color: #9ca1c7;
}

.inputRight .inputBox_addLead{
  width: 100%;
  height: 44px;
  margin-bottom: 15px;
  padding-left: 15px;
  border-radius: 24px;
  border-color: #363d72;
  background: var(--blakish);
  // color: #9ca1c7;
}


.addLeadAutoComplete .MuiAutocomplete-endAdornment{
  // right:0px !important;
}

.autoCompletePopper_addLead .MuiAutocomplete-listbox{
  background-color: var(--popUpColor) !important;
  color:#FFF
}
.autoCompletePopper_addLead .MuiAutocomplete-option.Mui-focused{
  background-color: var(--progressBarColor) !important;
}
.Sss{
  width: 500px;
}
.addLeadAutoComplete .MuiOutlinedInput-notchedOutline{
  border-radius: 24px;
  border-color: #363d72 !important;
  outline: 0;
  border-width: 0px !important;
}

.dateBorder{
  width: 100%;
  height: 44px;
  // margin-bottom: 15px;
  padding-left: 15px;
  border-radius: 24px;
  border-color: #363d72;
  background: var(--blakish);
  align-items: center;
  display: flex;
  cursor: pointer;
}

.inputRight .MuiInput-formControl{
  width: 100%;
  height: 44px;
  // margin-bottom: 15px;
  padding-left: 15px;
  border-radius: 24px;
  border-color: #363d72;
  background: var(--blakish);
}

.inputRight .MuiInput-underline:before{
  transform: scaleX(0); 
}
.inputRight .MuiInput-underline.Mui-focused:after {
   transform: scaleX(0); 
}
.inputRight .MuiInput-underline.Mui-error:after {
  transform: scaleX(0); 
  
}


.addLeadTextArea::-webkit-input-placeholder {
  color: #9ca1c7;
}

.addLeadTextArea:-moz-placeholder { /* Firefox 18- */
  color: #9ca1c7;  
}

.addLeadTextArea::-moz-placeholder {  /* Firefox 19+ */
  color: #9ca1c7;  
}

.addLeadTextArea:-ms-input-placeholder {
  color: #9ca1c7;  
}

.addLeadTextArea::placeholder {
  color: #9ca1c7;  
}


.addLeadInput::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #909;
}
.addLeadInput:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #909;
 opacity:  1;
}
.addLeadInput::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #909;
 opacity:  1;
}
.addLeadInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #909;
}
.addLeadInput::-ms-input-placeholder { /* Microsoft Edge */
 color:    #909;
}

.addLeadInput::placeholder { /* Most modern browsers support this now. */
 color:    #909;
}

