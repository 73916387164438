.bottomActionBarContainer {
  /* position: absolute;
    bottom: 2%;
    left: -50%;
    right: 0;
    background:var(--blakish);
    height: 40px;
    width: 500px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    overflow:hidden;
    z-index: 5; */
  /* background: var(--blakish); */
  background-color: #2f3453;
  box-shadow: 0px 0px 20px 9px rgba(255, 255, 255, 0.18);
  /* height: 40px; */
  height: 121px;
  width: 500px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  z-index: 18;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
  max-width: 100%;
  z-index: 99;
}

.selectedCircle {
  height: 10px;
  width: 10px;
  /* background: var(--lightBlue); */
  background-color: #669df5;
  border-radius: 50%;
  /* margin: 5px 3px 0px 0px; */
  margin-right: 3px;
}

.selectedCount {
  /* background-color: var(--lightBlue); */
  background-color: #669df5;
  /* height: 100%;
    width: 10%; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  position: absolute;
  top: -50%;
  transform: translateY(20%);
  margin-left: 40px;
  font-size: 54px;
}

.closeHover:hover {
  background-color: var(--primary);
  cursor: pointer;
  transition: ease-in-out;
}

.transitionInComing {
  transition: ease-in-out 1s;
}

.actionButton {
  background-color: #171b34;
  border-radius: 11px;
  height: 46px;
  width: 46px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  /* margin-top: 10px; */
}

.closeIcon_bottomActionBar {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-color: #625df5;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  position: absolute;
  transform: translate(-60%, -40%);
  left: 100%;
  top: 0%;
  /* margin-top: 10px; */
}
