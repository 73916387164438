.dialogContainer {
  border-radius: 8px;
  background-color: #fff;
  /* background-color: var(--secondary-background-color); */
  color: #323338;
  /* color: var(--primary-text-color); */
  box-shadow: 0 6px 20px -2px rgb(0 0 0 / 20%);
  max-width: 800px;
  min-width: 600px;
  font-size: 14px;
}

/* padding changed from '0 12' to '0 9 0 0' as checkbox is taking default padding  */
.tile {
  display: flex;
  align-items: center;
  padding: 0 9px 0 0;
  margin-right: 4px;
  height: 32px;
  /* max-width: 150px; */
  cursor: pointer;
  border-radius: 4px;
  color: #f5f6f8;
  background-color: #4b4e69;
  box-sizing: border-box;
  min-width: 150px;
  /* padding: 0 10px; */
  /* box-shadow: 0 6px 20px -2px rgb(0 0 0 / 20%); */
}

.activeTile {
  background-color: var(--milestoneRowAddColor);
  color: #fff;
}

/* .optionsContainer { */
/* margin-top: 8px; */
/* height: 208px !important; */
/* overflow-y: auto; */
/* padding: 0px 10px; */
/* } */

.textColumn {
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  max-width: 200px !important;
  font-size: 13px;
  font-family: "Lato-Regular";
  display: flex;
  align-items: center;
}

.optionsContainer {
  list-style-type: none;
  padding: 0px;
  max-height: 150px;
  overflow-y: scroll;
}

/* Scrollbar */

/* .optionsContainer::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
  border-radius: 10px;
} */

.optionsContainer::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.optionsContainer::-webkit-scrollbar-thumb {
  background-color: #4b4e69;
  border-radius: 10px;
}
