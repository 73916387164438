.container1 {
    // background: red;
    // margin: 20px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin: 20px 30px 0px 0px;

    .section1 {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        align-items: center;

        .sectionChild1 {
            border: 1px solid var(--progressBarColor);
            width: 50%;
            margin: 0px 5px 0px 0px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .filterIcon {
            width: 35px;
            height: 30px;
            cursor: pointer;
        }

        .sectionChild2 {
            background: var(--progressBarColor);
            width: 300px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
            margin-right: 15px;


            .iconBorder {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid;
                display: flex;
                align-items: center;
                justify-content: center;

                .plus {
                    font-size: 14px;
                    font-weight: 900;
                }
            }

            .text {
                font-size: 14px;
                margin-left: 5px;
            }
        }


    }

    .section2 {
        border: 1px solid var(--progressBarColor);
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 40px;
        min-height: 300px;

        .loading {
            width: 100%;
            height: 300px;
        }
    }
}