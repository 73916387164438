.drawerScheduleSection {
    color: white;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 38px;
        padding: 15px;

        .headerText {
            // padding: 40px 10px;
            // font-family: 'Lato';
            font-size: 20px;
        }

        .icon {
            width: 25px;
            height: 25px;
            cursor: pointer;
        }

    }

    .line {
        border-top: 1px solid var(--borderGrey);
        width: 100%;
        height: 1px;
    }

    .filterSection {
        padding: 15px;

        .mb {
            margin-bottom: 20px;

        }

        .df {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .btnDiv {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
        }
    }



}