.projectListSearchDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 25px 20px;

    .projectListSearchInput {
        background-color: #2D3463;
        height: 40px;
        border-radius: 25px;
        border: none;
        padding: 5px 15px 5px 45px;
    }

    .projectListSearchInput::placeholder {
        margin-left: 20px;
    }
}