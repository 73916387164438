.sidebar_backDrop {
  position: fixed;
  /* background: rgba(36, 42, 71, 0.5); */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  /* background-color: #212844; */
}

.sideBar_container {
  background-color: #0e0841;
  /* position: fixed; */
  /* background: #172032 !important; */
  height: 100%;
  width: 50%;
  right: 0;
  color: #fff;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  /* overflow-y: hidden; */
  /* margin: 15px 0px; */
  border-radius: 4px;
  padding: 10px;
  overflow: auto;
}

.sideBar_container {
  /* position: fixed; */
  /* background: #172032 !important; */
  /* height: 100%; */
  /* width: 50%; */
  right: 0;

  color: #fff;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  /* overflow-y: hidden; */
  /* margin: 15px 0px; */
  border-radius: 4px;
  padding: 10px;
}

.sideBarClose {
  position: fixed;
  top: 0;
  transform: translate(-25px, 5px);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #172032;
}

.sideBarClose:hover {
  background: var(--lightBlue);
  cursor: pointer;
}
