.myLead {
  padding: 1em;
  /* padding-right: 2em; */
  margin-top: 1.5em;
}

.leadDetails {
  /* background-color: var(--progressBarBgColor); */
  background-color: #292d4c;
  padding: 1.5em;
}

.info {
  color: var(--textColor);
  margin-top: 1em;
}

.info > * {
  display: flex;
  align-items: center;
  margin: 0.5em 0;
}

.info > * p {
  padding-left: 0.5em;
}

.leadDetails_info {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
}

@media (max-width: 800px) {
  .leadDetails_info {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
}

.leadDetails_info_left {
  margin-top: 1.5em;
  /* padding-right: 1em; */
  /* flex: 0.6; */
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leadDetails_info_left p {
  max-width: 90%;
}

.leadDetails_info_left_footer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

@media (max-width: 800px) {
  .leadDetails_info_left_footer {
    align-items: center;
  }
}

.leadDetails_info_left_footer_date {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-evenly; */
  /* width: 100%; */
  margin: 1em;
}

@media (max-width: 800px) {
  .leadDetails_info_left_footer_date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1em 0;
  }
}

.leadDetails_info_left_footer_date > * {
  margin: 1em 2em;
  margin-left: 0;
}

.leadDetails_info_left_footer_date div div::before {
  border: none !important;
}

.el {
  /* border: 1px solid var(--defaultWhite) !important; */
  /* padding: 0.5em !important; */
  margin: 0.5em 0 !important;
  padding: 0.5em 0.7em;
  border-radius: 4px;
  width: 11em;
  background-color: #f47ec8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateEl {
  /* border: 1px solid var(--defaultWhite) !important; */
  /* padding: 0.5em !important; */
  margin: 0.5em 0 !important;
  padding: 0.5em 1em;
  border-radius: 4px;
  width: 11em;
  background-color: #f47ec8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateEl input {
  color: var(--defaultWhite) !important;
  font-size: 0.9em;
  padding: 0.8em 0.5em !important;
}

.leadDetails_info_right {
  /* flex: 0.4; */
  display: flex;
  flex-direction: column;
}

.leadDetails_info_right div > * {
  /* flex-basis: 100%; */
  /* word-break: break-all; */
  /* overflow: hidden;
  flex-wrap: wrap; */
}

/* .leadDetails_info_right ul li {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
}

.leadDetails_info_right ul li > * {
  height: 100%;
  width: 100%;
  padding: 0.5em;
  padding-left: 1em;
  display: flex;
  flex-basis: 100%;
  border-right: 1px solid var(--divider);
  border-top: 1px solid var(--divider);
} */

.row {
  flex-basis: 100%;
  max-width: 50% !important;
  /* min-height: 55px !important; */
}

.rowEl {
  /* width: 100%; */
}

.activity {
  margin-top: 2em;
}

.activity_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0em;
  /* padding: 0 1em; */
}

.timeline {
  align-items: flex-start !important;
}

.activity li::before {
  flex: none !important;
}

.chip {
  width: 5em;
  border-radius: 30px;
  padding: 0.5em;
  background-color: red;
}

.description {
  word-break: break-all;
  padding-right: 1em;
}

.chips > * {
  margin: 1em 0;
  margin-right: 0.5em;
}
