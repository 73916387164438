.modal {
    // width: 30vw !important ;
    background-color: var(--newBlue);
    padding: 1em 2em 2em 2em;

    // border-radius: 14px;
    // overflow: hidden;

    .info-text {
        color: var(--defaultWhite);
    }

    .label {
        color: var(--lightGray);
        margin-bottom: 0.8em;
    }

    .button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .heading {
        color: var(--defaultWhite);
        text-align: center;
        font-size: 2em;
    }

    .loadingRow {
        display: flex;
        align-items: center;
    }



}

.askDelete {
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: var(--milestoneRowElColor);

    .text {
        font-size: 25px;
        margin-bottom: 25px;
        color: white;
    }
}