// .leadInfoHeader{
//     padding: 20px;
// }
.leadinfoClose  span {
    width: 38px;
    height: 38px;
    background:var(--projectCardBg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0px 0px 0px auto;
}
.leadinfoHeading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0px;
}
.leadinfoHeading .badge {
    background: #2afa76;
    color: #fff;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    margin: 0px 20px 0px;
    height: 18px;
}
.heartIcn {
    width: 28px;
    height: 28px;
    background:var(--projectCardBg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leadinginfoTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.folowBtn {
    height: 36px;
    width: 160px;
    background: var(--primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.activityBoxlist {
    display: flex;
    flex-wrap: wrap;
}
.activityItem {
    background: #586094;
    margin-right: 10px;
    padding: 6px 10px 6px;
    border-radius: 20px;
    font-size: 12px;
    margin-bottom: 5px;
    &:hover{
        background-color: var(--violet);
    }
}

.selectedActivityItem{
    background-color: var(--orange);
}

.activityBox {
    background: #41487a;
    padding:20px 20px 20px;
    border-radius: 4px;
}
.activitytabContent {
    margin-top: 20px;
}
.activityForm {
    margin-top:20px;
}
.activityForm_input {
    width: 100%;
    border: 1px solid #999;
    height: 43px;
    border-radius: 20px;
    // margin-bottom: 10px;
    padding: 0px 20px 0px;
    color: #171b34;
    background: transparent;
    display: flex;
    align-items: center;
    color:#FFF
}
.activityForm .btn {
    background: #625df5;
    width: 96px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    border-radius: 20px;
    margin: 15px 0px 0px auto;
}
.activityListIcn {
    background: var(--projectCardBg);
    padding: 10px 18px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    border-radius: 4px;
    position: relative;
}
.spanBox {
    background:#171b34;
    margin-right: 10px;
    padding:2px 10px 2px;
    font-size: 18px;
    color: var(--primary);
}
.spanBox span {
    display: inherit;
    font-size: 12px;
    color: var(--primary);
}
.activityListIcn h3 {
    font-weight: 500;
}
.activityListIcn p {
    color: #9ca2cb;
}
.activityList {
    margin-top: 50px;
}
.activityListIcn::after {
    content: "";
    width: 2px;
    height: 36px;
    background: #625df5;
    position: absolute;
    top: 74px;
    left: 19px;
}

.activityList .activityListIcn:last-child::after{
    display: none !important;
}

.activityList .activityListIcn:last-child{
    margin-bottom: 0px;
}

////********About Tab Content**********////

.abouttabContent{margin-top: 20px;}

.abouttabContent .table-bordered {
    border: 1px solid #454767;
}
.abouttabContent .table-bordered td {
    border: 1px solid #454767;
    padding: 0.5rem 0.75rem 0.5rem;
    vertical-align: top;
}
.abouttabContent .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
}
// .abouttabContent p {
//     margin: 20px 0px;
// }
.abouttabContent ul{
    margin-bottom: 25px;
}
.abouttabContent ul li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
    color: #9d9aff;
}
.abouttabContent ul li svg {
    margin-right: 10px;
}