.view_invoice_container {
  padding: 20px;

  .companyLogo {
    height: 43px;
    width: 121px;
    object-fit: contain;
  }
  .view_invoice_container_button {
    border-radius: 25px !important;
  }
  .companyAddress {
    font-size: 16px;
    text-align: end;
  }
  .font20 {
    font-size: 20px;
    margin: 5px 0px;
  }
  .font18 {
    font-size: 18px;
  }

  .font25 {
    font-size: 25px;
  }
  .invoice_details {
    background-color: var(--progressBarColor);
    padding: 20px;
    border-radius: 8px;
    .billingAddressContainer {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      flex: 0.5;
    }
  }

  .invoice_box {
    border: 1px solid var(--divider);
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    flex-wrap: wrap;

    .invoice_box_item {
      flex: 1;
      :first-child {
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        margin-bottom: 10px;
      }
      :last-child {
        font-size: 18px;
      }
    }
  }
  table {
    width: 100%;
    margin-bottom: 20px;
    thead {
      td {
        color: rgba(255, 255, 255, 0.5);
        font-size: 18px;
      }
    }

    tbody {
      td {
        font-size: 15px;
      }
    }
  }
  .service_details_row {
    padding: 20px 0;
    border-bottom: 1px solid var(--divider);
  }

  .invoice_transaction_header_row {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr 0.1fr;
    font-size: 16px;
    font-family: "Lato-Bold";
    margin: 10px 0;
  }

  .invoice_transaction_row_item {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr 0.1fr;
    background-color: var(--projectCardBg);
    height: 40px;
  }

  .invoice_item_transaction_sideLine {
    width: 5px;
    height: inherit;
    background-color: var(--green);
    border-bottom: 1px solid #191b34;
  }
  .invoiceFooter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    .balanceDue {
      color: #fff;
      font-size: 20px;
      font-family: "Lato-Bold";
    }
    .invoiceFooter_left {
      justify-content: flex-end;
      :first-child {
        flex: 0.5;
        text-align: start;
      }
      :last-child {
        flex: 0.5;
        text-align: end;
      }
    }
    .invoiceFooter_right {
      // min-width: 200px;
      justify-content: flex-end;
      :first-child {
        flex: 0.5;
        text-align: end;
      }
      :last-child {
        flex: 0.5;
        text-align: end;
      }
    }
  }
}


.addTransactionContainer{
   
}