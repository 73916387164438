.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.projectList {
    display: flex;
    flex: 0.3;
    flex-direction: column;
    border-right: 1px solid var(--border);
    overflow-y: scroll;
    height: 90vh;
}

.projectListItem {
    margin: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid var(--border);
    transition: all 0.1s ease-in;
    padding: 10px;
    /* margin: 1em; */
}

.projectList .active {
    background-color: var(--progressBarColor);
    position: sticky;
    top: 0;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    /* margin: 0em; */
}

.projectListItem:hover {
    opacity: 0.9;
    background: var(--box-color-opacity);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid var(--border);
}