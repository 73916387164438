.update_section {
    margin-bottom: 20px;
  .date_title {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--primary);
    margin-bottom: 1rem;
  }
  .update_container {
    background-color: var(--projectCardBg);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
  }

  .highlightText{
    color: var(--primary);
    /* font-size: 0.5em; */
  }
}
