.wrapper {
  /* background-color: var(--projectCardBg); */
  /* padding: 0.5em; */
  margin: 1em auto;
  border-radius: 4px;
  align-self: center;
  display: flex;
  align-items: center;
  align-items: center;
  flex-wrap: wrap;
}

.wrapper > * {
  /* margin: 0 0.5em !important; */
}
