.PopoverContainer .containerCSS {
  display: flex;
  font-size: 13px;
  color: #fff !important;
  align-items: center;
  padding: 10px;
  border-radius: 0%;
  width: 100%;
  cursor: pointer;
}

.PopoverContainer .containerCSS:hover {
  background-color: var(--primary);
}
.PopoverContainer .containerScroll {
  max-height: 300px;
  overflow-y: auto;
}
.PopoverContainer .projectName {
  font-size: 13px;
  display: flex;
  flex: 1;
}
.paperClass {
  /* background-color: #2A304D !important; */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 9px -1px rgb(0 0 0 / 17%),
    0px 1px 12px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.popperClassColor {
  background-color: #2a304d !important;
}

.PopoverContainer {
  min-height: auto !important;
}

.PopoverContainer .colorClass {
  color: var(--defaultWhite);
}

.PopoverContainer .colorClass:hover {
  background-color: transparent;
}
.divider_color {
  background-color: #efefef !important;
  opacity: 0.1 !important;
}

.textEllipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 122px;
}
