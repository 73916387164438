.containerEod {
  margin-left: 20px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 25px;
  /* font-size: 25px; */

  .flexRowSpaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  #openFilter {
    display: none;
  }

  #openFilter:checked ~ .clickToOpenFilter {
    max-height: 550px;
    transition: all 0.5s ease-in;
    overflow: auto;
  }

  .clickToCloseFilter {
    max-height: -0px;
    padding: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;
    position: absolute;
    z-index: 11;
    right: 0px;
    min-width: 250px;
  }

  .clickToOpenFilter {
    max-height: 520px;
    transition: all 0.5s ease-in;
    overflow: auto;
    position: absolute;
    right: 0px;
    min-width: 370px;
    z-index: 2;
  }

  .MuiDrawer-paperAnchorRight {
    transform: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: rgb(32, 25, 87);
    min-width: 400px;
    border-radius: 55px;
    height: 600px;
  }

  .Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .filter_badge {
    height: 20px;
    width: 20px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 12px;
    padding-bottom: 2px;
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
