$animationDuration: 1s;
$animationDelay: 0.075s;

.calendar {
  display: flex;
  // justify-content: space-between;
  overflow: hidden;
  flex-direction: column;
  // height: 100vh;
}

@media (min-width: 1400px) {
  .mainCalComp {
    font-size: 1.3em !important;
    // background-color: red !important;
  }
  .tiles {
    abbr {
      font-size: 1.3em !important;
    }
  }
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.mainCalComp {
  overflow: hidden;
  height: 100%;
  width: 100%;
  // margin-left: 1em;
  padding: 1em;
  // margin-top: 1em;
  background-color: transparent;
  border: none;

  & .react-calendar__viewContainer {
    height: 100%;
  }
  button[disabled] {
    color: #f3f3f367;
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  // navigation

  .react-calendar__navigation {
    // display: grid !important;
    border-radius: 4px;
    // width: 100% !important;
    width: max-content;
    // justify-content: center;
    // border: 1px solid var(--progressBarColor);
    // background: var(--projectCardBg);
    background: var(--progressBarColor);
    -webkit-box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.438);
    box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.466);
    // min-width: 250px;
    margin: auto;
    margin-bottom: 1em;
    padding: 0.6em 0;
    // grid-template-areas: "label prev next";
    // grid-template-columns: 7fr auto auto;

    button[disabled] {
      color: #f3f3f367;
    }

    .react-calendar__navigation__prev2-button {
      grid-area: prev2;
    }
    .react-calendar__navigation__prev-button {
      grid-area: prev;
    }
    .react-calendar__navigation__label {
      grid-area: label;
      text-align: center !important;
      // text-align: left !important;
      font-size: large;
      min-width: 180px;
      span {
        // background: var(--progressBarColor);
        // -webkit-box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.438);
        // box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.466);
        padding: 0.4em 0.7em;
        border-radius: 4px;
      }
    }
    .react-calendar__navigation__next-button {
      grid-area: next;
    }

    button {
      text-align: left;
      background: none;
      // background: var(--projectCardBg);
      // background: var(--progressBarColor);
      // -webkit-box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.438);
      // box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.466);
      color: #fff;
      border: none;
      margin: 0.2em 0.3em;
      // padding: 0.4em;
      cursor: pointer;
    }
    .react-calendar__navigation__label {
      // min-width: 130px;
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .btn {
      padding: 0 !important  ;
      margin: 0 1em;
      // background: var(--progressBarColor);
      // -webkit-box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.438);
      // box-shadow: 1px 9px 15px -4px rgba(0, 0, 0, 0.466);
    }
  }

  // month view
  .react-calendar__year-view {
    height: 70vh;
  }
  .react-calendar__year-view__months {
    height: 100%;
    button {
      text-align: center !important;
      justify-content: center;
      display: flex;
      width: 100%;
      padding: 2em;

      abbr {
        width: 100%;
        font-size: 1.3em;
      }
    }
  }

  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    height: 70vh;
    button {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size: 1.3em;
    }
  }

  // week days

  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    & > * {
      // background-color: var(--progressBarColor);
      padding: 0.3em 0.6em;
      border-radius: 4px;
      abbr {
        text-decoration: double !important;
        font-size: 2em !important;
      }
    }
  }

  .react-calendar__month-view {
    height: 100%;
  }

  // dates

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid var(--divider);
    border-radius: 4px;
    grid-template-rows: repeat(auto-fill, 1fr);

    height: 60vh;

    // & button {
    //   width: 100% !important;
    //   height: 100% !important;
    //   max-width: 100% !important;
    // }
    .tiles {
      transition: all 0.3s linear;
      &:hover {
        transition: all 0.3s linear;
        // -webkit-box-shadow: 6px 5px 21px 21px rgba(0, 0, 0, 0.22);
        // box-shadow: 6px 5px 11px 21px rgba(0, 0, 0, 0.22);
      }
      &:hover abbr {
        // background-color: var(--progressBarColor);
      }
    }
    .react-calendar__tile--now {
      abbr {
        background-color: var(--primary);
        height: 33px;
        width: 35px;
        border-radius: 4px;
      }
    }
    .react-calendar__tile--active {
      background: #090d27;
      abbr {
        // background-color: var(--progressBarColor);
      }
    }
    .tiles {
      width: 100%;
      // height: 100%;
      // max-width: 100% !important;
      // background: none;
      // color: #fff;
      // border: none;
      // border-left: 1px solid var(--divider);
      // border-bottom: 1px solid var(--divider);
      // display: flex;
      // flex-direction: column;
      // align-items: flex-end;
      // justify-content: space-evenly;
      // cursor: pointer;
      // background-color: var(--projectCardBg);
    }
  }
  .tiles {
    // width: 100%;
    // height: 100%;
    max-width: 100% !important;
    background: none;
    color: #fff;
    border: none;
    border-left: 1px solid var(--divider);
    border-bottom: 1px solid var(--divider);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    cursor: pointer;
    background-color: #191b34;
    // background-color: var(--projectCardBg);
    // background-color: #2e3a8e;

    abbr {
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 5px;
      transition: background-color 0.3s linear;
      border-radius: 50%;
    }
    abbr[title] {
      text-decoration: none !important;
    }
    // &:hover abbr {
    //   background-color: var(--progressBarColor);
    // }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    background-color: rgba(82, 94, 139, 0.1);
    color: var(--lightGray);
  }
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0.2em;
}

// .eventsListEl {
//   list-style: none;
// }

// .eventsListEl {
//   background-color: #2e7f44b9;
//   padding: 0.3em 0.5em;
//   margin-top: 0.4em;
//   border-radius: 3px;
//   box-shadow: -10px 4px 5px -7px rgba(0, 0, 0, 0.56);
//   -webkit-box-shadow: -10px 4px 5px -7px rgba(0, 0, 0, 0.56);
//   -moz-box-shadow: -10px 4px 5px -7px rgba(0, 0, 0, 0.56);
//   cursor: pointer;
//   transition: opacity 0.5s linear;
//   transform: translateY(-100%) scaleY(1);
//   animation-name: insert-from-top;
//   animation-duration: $animationDuration;
//   animation-fill-mode: forwards;
//   // transform: skew(20deg, 20deg) rotate(20deg);
//   opacity: 0;
//   @for $i from 1 through 20 {
//     &:nth-child(#{$i}) {
//       animation-delay: $i * $animationDelay;
//       z-index: $i;
//     }
//   }
//   /* animation: topToBottom 0.5s linear; */
// }

.dateInfo {
  padding: 1em;
  display: flex;
}

.info {
  display: flex;
  margin: 1em;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // align-items: flex-start;
  li {
    margin: 1em;
    text-align: left;
    text-transform: uppercase;
  }
}

// $animationDuration: 1s;
// $animationDelay: 0.075s;

.eventsList {
  width: 700px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* height: 10em; */
  // margin-top: 2em;
  // list-style: none;
}

.eventsListEl {
  margin: 0.5em 1em;
  min-width: 15em;
  min-height: 3em;
  display: flex;
  align-items: center;
  padding: 0.4em 0.5em;
  border-radius: 3px;
  box-shadow: -10px 4px 5px -7px rgba(0, 0, 0, 0.56);
  -webkit-box-shadow: -10px 4px 5px -7px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: -10px 4px 5px -7px rgba(0, 0, 0, 0.56);
  cursor: pointer;
  transition: all 0.5s linear !important;
  transform: translateY(-100%) scaleY(1);
  animation-name: insert-from-top;
  animation-duration: $animationDuration;
  animation-fill-mode: forwards;
  // transform: skew(20deg, 20deg) rotate(20deg);
  // opacity: 0.7 !important;
  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      animation-delay: $i * $animationDelay;
      z-index: $i;
    }
  }
  /* animation: topToBottom 0.5s linear; */
  &:hover {
    opacity: 1 !important;
    transition: all 0.5s linear !important;
  }
}

.eventsList li {
  // transform: translateY(-100%) scaleY(1);
  // animation-name: insert-from-top;
  // animation-duration: $animationDuration;
  // animation-fill-mode: forwards;
  // opacity: 0;
  // @for $i from 1 through 20 {
  //   &:nth-child(#{$i}) {
  //     animation-delay: $i * $animationDelay;
  //     z-index: $i;
  //   }
  // }
  opacity: 0;
}

@keyframes topToBottom {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes flip {
  0% {
    transform: rotateX(0deg);
    position: absolute;
    opacity: 1;
  }
  50% {
    transform: rotateX(180deg);
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    /* transform: translateY(0px); */
  }
  100% {
    transform: rotateX(0deg);
    position: absolute;
    opacity: 0;
    z-index: -1;
    /* transform: translateY(0px); */
  }
}

@keyframes insert-from-top {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    transform: translateY(30%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// Modal
.dateCalenderClass {
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
}

.dateLabel {
  font-size: 13px;
  margin-bottom: 5px;
}
