/* :root {
    --color-first: #e1d9f0;
    --color-second: #f18867;
    --color-third: #e85f99;
    --color-forth: #50bda1;
    --block-width: 150px;
    --block-height: 35px;
    --border-width: .625rem;
    --border-radius-outer: 4px;
    --border-radius-inner: calc(var(--border-radius-outer) / 2);
    --font-plain: "IBM Plex Sans", sans-serif;
    --font-special: "Fredoka One", sans-serif;
    box-sizing: border-box;
    line-height: 1.4;
  }

  .rainbow {
    width: 100%;
    height: 100%;
    -webkit-animation: o-rotate-360 linear 8s infinite;
            animation: o-rotate-360 linear 8s infinite;
  }

  
.rainbow span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(-50%, -50%);
  }
  .rainbow span:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 100%;
  }
  .rainbow span:first-child {
    background: var(--color-first);
  }
  .rainbow span:first-child:after {
    background: var(--color-second);
  }
  .rainbow span:last-child {
    background: var(--color-third);
  }
  .rainbow span:last-child:after {
    background: var(--color-forth);
  }

  
.c-subscribe-box {
    width: var(--block-width);
    height: var(--block-height);
    overflow: hidden;
    position: relative;
    box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
    border-radius: var(--border-radius-outer);
  }
  .c-subscribe-box__wrapper {
    width: calc(100% - var(--border-width));
    height: calc(100% - var(--border-width));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1.2rem 1rem 1.8rem;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-inner);
  }
  .c-subscribe-box__title {
    font-size: 1.6rem;
  }
  .c-subscribe-box__desc {
    font-size: 0.935rem;
    margin: 0.7rem auto 1.8rem;
    max-width: 240px;
  }
  .c-subscribe-box__form {
    margin-top: auto;
  }
  
  .c-form--accent input:hover, .c-form--accent input:active, .c-form--accent input:focus {
    border-color: var(--color-third);
    box-shadow: 0 0 0 3px rgba(232, 94, 152, 0.25);
  }
  .c-form--accent [type=submit] {
    background: var(--color-third);
    border-color: var(--color-third);
    color: #fff;
  }
  
  @-webkit-keyframes o-rotate-360 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes o-rotate-360 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  [type=submit] {
    margin-bottom: 0;
    font-family: var(--font-special);
    font-weight: normal;
    letter-spacing: 0.015em;
    font-size: 1.1rem;
  }
  [type=submit]:active {
    transform: scale(0.97);
  }
  
  input {
    font-family: inherit;
    color: inherit;
    outline: none;
    font-size: 93%;
    transition: all 300ms ease;
  }
  
  h3 {
    margin: 0;
    letter-spacing: -0.015em;
    font-family: var(--font-special);
    font-weight: normal;
    line-height: 1.4;
  }
  
  .u-align-center {
    text-align: center;
  } */


  .create-button {
    /* font-family: monospace; */
    /* font-weight: bold; */
    /* text-transform: uppercase; */
    background: #000;
    color: #fff;
    border: none;
    border-radius: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    position: relative;
    transform: translate(-50%, -20%);
    border-radius: 3px;
    cursor: pointer;
    /* padding: 0.8em 1.6em; */
    font-size: 18px;
    overflow: hidden;
    animation: rainbow-border 2.5s linear infinite;
  }
  .create-button:focus,
  .create-button:active {
    outline: none;
  }
  .create-button:before,
  .create-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    width: auto;
    z-index: -1;
    border-radius: inherit;
    background: inherit;
  }
  .create-button:before {
    background: linear-gradient(90deg, #e1d9f0, #f18867, #ae63e4, #47cf73, #0ebeff, #ffdd40, #ae63e4, #47cf73);
    background-size: 200% 200%;
  }
  .create-button:after {
    margin: 2px;
  }
  .create-button:before,
  .create-button:before {
    animation: rainbow-border 2.5s linear infinite;
  }
  @keyframes rainbow-border {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 200% 50%;
    }
  }
  

.cell{
    /* border : 1px solid var(--divider); */
    padding: 5px 10px;
    border-radius: 4px;
    min-width: 100px;
    /* text-align: center; */
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}




@-webkit-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.rainbow {
  position: relative;
  z-index: 0;
  /* width: 100px;
  height: 35px; */
  border-radius: 10px;
  overflow: hidden;
  padding: 0.7rem;
}
.rainbow::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -100%;
  width: 200%;
  height: 300%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(var(--progressBarColor), var(--progressBarColor)), linear-gradient(var(--primary), var(--primary)), linear-gradient(var(--green), var(--green)), linear-gradient(var(--red), var(--red));
  -webkit-animation: rotate 15s linear infinite;
          animation: rotate 15s linear infinite;
}
.rainbow::after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: #171b34;
  border-radius: 5px;
}