.detailsArea {
    // padding: 0 1em;
}

.listArea {
    height: 90vh;
    padding-bottom: 10px;
    overflow: scroll;
}

.filter {
    display: flex;
    padding: 0 1em;
    flex-direction: column;
    align-items: center;
    max-width: 1025px;

    .status {
        display: flex;
        // width: 100px;
        justify-content: space-between;
        align-items: center;
        margin: 20px;

        .selected {
            color: var(--primary);
        }

        .radioDiv {
            display: flex;
            align-items: center;
            margin-right: 20px;
        }


    }
}