.button {
    width: 5.5em;
    height: 2em;
    // padding: 5px 10px;
    border-radius: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 1em;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }
}