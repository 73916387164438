.credentialsRow {
  display: grid;
  grid-template-columns: 1em 30% 30% 1fr 0.2fr;
  /* padding: 1em; */
  /* padding-left: 3em; */
  background-color: var(--milestoneRowColor);
  height: 45px;
  /* display: flex; */
  align-items: center;
}

.credentialsRow > * {
  /* margin: ; */
}

.credentialsRowEl {
  display: grid;
  grid-template-columns: auto 30% 30% 1fr 0.2fr;
  background-color: var(--milestoneRowElColor) !important;
  align-items: center;
  height: 35px;
}

.credentialsRowEl > * {
  /* margin-right: 2em !important; */
}

.checkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  margin-right: 0.5em;
  width: 10%;
  cursor: pointer;
  transition: width 0.2s ease-in-out;
}

.checkBox:hover {
  width: 90% !important;
}

.checkBox:hover > * {
  display: block !important;
}

.rowEL {
  overflow: hidden;
  padding-left: 1em;
}
