.userListBox {
    width: 100%;


    .listHeader {
        background: var(--lightPurple);
        border-radius: 6px;
        padding: 15px;
        color: grey;
    }

    .userList {
        background: var(--listBgGrey);
        margin: 0px 5px;
        padding: 10px;



        .listRow {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            width: fit-content;
            cursor: pointer;

            .imgNameDiv {
                display: flex;
                align-items: center;


                .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 12px;
                    border: 1px solid;
                }
            }
        }
    }

    .mb {
        margin-bottom: 20px;
    }

    .flexStart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;

        .textInRadio {
            cursor: pointer;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            color: #FFFFFF;
        }

    }


}