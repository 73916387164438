.invoiceArea {
    display: flex;
    overflow: hidden;
    height: 90vh;
    flex: 0.3;
}

.paymentScheduleArea {
    display: flex;
    flex-direction: column;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
