.listSalary{
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
}

.numberBox{
    border : 1px solid;
    padding: 0px 10px;
    margin: 10px 50px 10px 10px;
    border-radius: 4px;
}

.inputContainer .inputBox{
    /* border: 1px solid var(--lightBlue); */
    /* padding: 10px; */
    border-radius: 20px;
    margin: 15px 15px;
}

.inputContainer .MuiOutlinedInput-notchedOutline{
    border-color: var(--lightBlue);
}

.salaryBlock{
    background-color: var(--progressBarColor) ;
    height: 90%;
    width:90%;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}