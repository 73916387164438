.bellIconContainer{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    position: relative;

}
.notificationPopup{
    
position: absolute;
bottom: 0;
height: 300px;
width: 300px;
background-color: #2f3453 !important;
transform: translate(-43% ,108%);
border-radius: 8px;
}



.notificationPopup:after, .notificationPopup:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.notificationPopup:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #2f3453 !important;
	border-width: 12px;
	margin-left: -12px;
}
.notificationPopup:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #2f3453 !important;
	border-width: 18px;
	margin-left: -18px;
}


  .bell_count{
      position: absolute;
      top: 0px;
      right: 0px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: var(--red);
      color: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
  }


  .notificationRow{
      border-bottom: 1px dashed var(--divider);
      padding: 10px;
      font-size: 13px;
      display: flex;
      &:hover{
          background-color: var(--divider);
          cursor: pointer;
      }

      .notificationRow_time{
          font-size: 11px;
          color: var(--primary);
      }

      .notification_dot{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--lightBlue);
      }
  }