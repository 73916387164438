.projectContainer .projectCard {
  width: 100%;
  /* height: 100%; */
  /* background: var(--newBlueLight); */
  background: transparent;
  /* background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0.993) 0%
  ); */
  background: linear-gradient(
      to right,
      #ffffff78 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(#ffffff78 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #ffffff78 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#ffffff78 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 13px 2px, 2px 13px;
  /* color: #fff !important  ; */
}

.projectContainer {
  width: 100%;
  display: grid !important;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-columns: repeat(3 , 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  grid-gap: 10px;
}

.projectContainer2 .projectCard2 {
  width: 100%;
  /* height: 100%; */
  /* background: var(--newBlueLight); */
  background: transparent;
  /* background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0.993) 0%
  ); */
  background: linear-gradient(
      to right,
      #ffffff78 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(#ffffff78 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #ffffff78 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#ffffff78 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 13px 2px, 2px 13px;
  /* color: #fff !important  ; */
}

.projectContainer2 {
  width: 100%;
  display: grid !important;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-columns: repeat(3 , 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(23em, 1fr));
  grid-gap: 10px;
}

.deadLine {
  color: var(--red);
}

.chipContainer {
  min-height: 1.7em;
  color: rgb(247, 143, 8);
}

.btnWrapper {
  background-color: var(--projectCardBg);
  padding: 1em;
  /* width: auto; */
  /* display: inline; */
  display: block;
  /* align-self: center; */
  border-radius: 30px;
  margin: 1em auto;
}

.blockSensitive {
  border: 1px solid var(--divider);
  padding: 10px 0px 10px 5px;
  background: #2d3463;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.ArrowIcon {
  margin-right: 0.5em;
  transition: all 0.3s linear;
}

.ProjectTitle {
  font-family: "Lato-Bold";
  font-size: 19px;
}

.tableContainer {
  /* animation: scale 0.3s linear;
  transform-origin: top;
  transform: scaleY(1); */
}

@keyframes scale {
  from {
    transform: scaleY(0);
    /* transform: translateY(-50px); */
  }
  to {
    transform: scaleY(1);
    opacity: 1;
    /* transform: translateY(0); */
  }
}

.tableContainer2 {
  animation: scale2 0.3s linear;
  transform-origin: top;
  transform: scaleY(0) translateY(-100px);
}

@keyframes scale2 {
  from {
    transform: scaleY(1);
    /* transform: translateY(-50px); */
  }
  to {
    transform: scaleY(0);
    opacity: 1;
    /* transform: translateY(0); */
  }
}
