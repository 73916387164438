.icon {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 9999 !important;
  color: var(--defaultWhite) !important;
  border: 1px solid var(--divider) !important;
}

.disable {
  cursor: not-allowed !important;
  pointer-events: all !important;
  opacity: 0.5;
}
