.welcomeBanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.wave {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 85%;
  width: 55%;
}

.lottie {
  position: absolute;
  right: 0;
  bottom: 0;
  /* top: 20%; */
  left: 60%;
}

.welcomeBanner svg {
  height: 100%;
  width: 100%;
}

@media (max-width: 700px) {
  .welcomeBanner {
    display: none;
  }
}
