.buttonArea {
    display: flex;
    justify-content: space-between;
    padding: 0 1em;

    .iconWrapper {
        background-color: var(--progressBarColor);
        border-radius: 50%;
        /* width: 49px;
  height: 49px; */
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }

    .displayFlex {
        display: contents;
        cursor: pointer;
    }

    .filterCount {
        border-radius: 50%;
        position: relative;
        background: var(--milestoneRowAddColor);
        color: #fff;
        width: 15px;
        height: 16px;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 10px;
        left: 15px;
    }

}

.headingList {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .text {
        width: 10%;
    }
}