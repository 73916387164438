.yearMonthPicker {
    display: flex;
    align-items: baseline;

    .switchAll {
        display: flex;
        align-items: center;

        p {
            font-size: 26px;
            font-weight: 500;
        }

        .MuiSwitch-track {
            background-color: gray !important;
        }

        .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
            background-color: #625df5 !important;
        }
    }

    .btn {
        width: 25px;
        height: 25px;
        margin: 0px 25px;
        background-color: rgb(98, 93, 245);
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
    }

    .btnDisable {
        background-color: grey !important;
        cursor: context-menu !important;
        visibility: hidden !important;
    }

    .dateYear {
        display: flex;
        align-items: center;

        .text {
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
        }

        .mr {
            margin-right: 10px;
        }
    }
}