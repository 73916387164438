.sectionBox {


    .title {
        font-size: 13px;
        color: var(--primary);
        text-transform: capitalize;
        margin-bottom: 8px;
    }

    .containerFilter {
        max-height: 150px;
        overflow-y: scroll;
        margin-right: 15px;

        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */

        .selectSection {

            display: flex;
            align-items: center;
            padding: 0 9px 0 0;
            margin-right: 5px;
            height: 32px;
            // max-width: 150px;
            cursor: pointer;
            border-radius: 4px;
            color: #f5f6f8;
            background-color: #4b4e69;
            box-sizing: border-box;
            min-width: 150px;
            padding: 0 10px;
            box-shadow: 0 6px 20px -2px rgb(0 0 0 / 20%);
            margin-bottom: 8px;
        }
    }




}