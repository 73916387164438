.my-popover-menu {
    &-item {
        cursor: pointer;
        margin: 0.5em 0;
        padding: 0.7em 1.2em;

        &:hover {
            background-color: var(--box-color);
            color: var(--defaultWhite);
        }
    }
}
