.milestoneContainerCard {
  width: 100%;
  display: grid !important;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-columns: repeat(3 , 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(20 em, 1fr));
  grid-gap: 10 px;
}

.milestoneAdd {
  width: 100%;
  /* height: 100%; */
  /* background: var(--newBlueLight); */
  background: transparent;
  /* background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0.993) 0%
  ); */
  background: linear-gradient(
      to right,
      #ffffff78 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(#ffffff78 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #ffffff78 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#ffffff78 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 13px 2px, 2px 13px;
  /* color: #fff !important  ; */
}

.projectBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* padding: 0.5em 1em; */
  /* background-color: var(--projectCardBg); */
  border: 1px solid #ffffff78;
  border-radius: 10px;
  color: var(--defaultWhite);
  height: 100%;
  /* overflow: hidden; */
  /* max-width: 20em; */
  min-height: 12em;
}

.projectBox > * {
  /* margin: 0.5em 0; */
}

.projectBox__header {
  /* display: flex; */
}

.projectBox__header > * {
  margin-right: 1em;
}

.headDetails {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* width: 100%; */
  /* align-items: center; */
  flex: 1;
}

.projectName {
  /* width: 60%; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
  /* width: 80%; */
  /* padding-right: 5px;s */
}

.avatar {
  width: 3em !important;
  height: 3em !important;
}

/* .avatarGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

.avatarGroup {
  transition: margin 0.3s linear;
}

.avatarGroup:hover > * {
  margin-right: 0.5em;
  transition: margin 0.3s linear;
}

.avatarGroup > * {
  margin-top: 0.4em;
  width: 1.3em !important;
  height: 1.3em !important;
  background-color: var(--defaultWhite);
}

.projectBox__tags {
  display: flex;
  margin-top: 0.4em;
  /* height: 1.3em; */
  /* margin: 0.2em 0; */
  /* font-size: 0.4em; */
}

.headerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerInfo > * {
  font-size: 10px;
}

.info {
  color: var(--textColor);
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.chip {
  font-size: 0.7em !important;
}

.projectBox__progress {
  display: flex;
  align-items: center;
}

.projectBox__progress > * {
  margin-right: 1em;
}

.projectBox__progress__progressBar {
  width: 100%;
  transition: all 0.7s ease-out;
  border-radius: 10px;
  height: 0.7em;
}

.projectBox__footer {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em !important;
}

.milestoneCard_ .milestoneSection2 {
  display: flex;
  border: 1px dashed #ffffff78;
  border-width: 1px 0px;
  margin: 0 0 10px 0;
}

.milestoneSection2 .milestoneSection2_innerSection {
  border-right: 1px dashed #ffffff78;
  flex: 1;
  padding: 10px 10px;
}

.milestoneSection2_innerSection2 {
  flex: 0.5;
  border-right: 1px dashed #ffffff78;
  padding: 10px 10px;
}

.milestoneSection2_innerSection3 {
  flex: 0.5;

  padding: 10px 10px;
}

.milestoneDisable {
  opacity: 0.5;
  cursor: default;
}
