.headerDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.headerDeadline {
  display: flex;
  color: var(--red);
  margin: 0.5em 0.5em 0.5em 0;
  min-width: 5em;
}

.btnBar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.iconWrapper {
  background-color: var(--progressBarColor);
  border-radius: 50%;
  /* width: 49px;
  height: 49px; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.avatars {
  padding-left: 1em;
}

/* .avatars span div > * {
  transition: margin 0.3s linear;
}

.avatars span div > *:hover {
  padding-left: 0.4em;
  transition: margin 0.3s linear;
} */

.textWrapper > * {
  display: flex;
  /* justify-content: flex-end; */
  position: absolute;
  opacity: 0;
  /* animation: fadeOut 10s linear; */
}

.textWrapper .textAnime2 {
  position: absolute;
  opacity: 0;
  animation: fadeOut 1s linear;
}

.textWrapper .textAnime {
  position: relative;
  opacity: 1;
  animation: fade 1s linear;
}

.tableContainer {
  animation: fade 0.3s linear;
  background-color: red;
}

@keyframes fade {
  from {
    opacity: 0;
    /* transform: translateY(-50px); */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    /* transform: translateY(0px); */
  }
  to {
    opacity: 0;
    /* transform: translateY(-50px); */
  }
}



