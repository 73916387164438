.tableHeaderBox {
    display: grid;
    background-color: #2D3463;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr 1fr;



    .tableBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .tableBox2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        margin-left: 60px;
    }
}

.tableRowBox {
    display: grid;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr 1fr;

    .tableBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .clientNameBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .clientNameBoxChild {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 20px;
            }
        }
    }


}

.loader {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}