.payment-schedule-header {
    // padding: 0;
    transition: all 0.3s ease-in;
    max-height: 0;
    opacity: 0;
    position: absolute;

    .nameRow {
        height: 0;
        // display: none;
        transition: all 0.3s ease-in 0.3s;
    }

    .amount-row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}

.payment-schedule-header-active {
    padding: 1.5em;
    transition: all 0.3s ease-in;
    max-height: 90vh;
    opacity: 1;

    .nameRow {
        display: flex;
        justify-content: space-between;
        transition: all 0.3s ease-in 0.3s;

        p {
            font-size: 2em;
        }

        .button {
            width: 7em;
            height: 2.5em;
            border-radius: 1em;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                -webkit-user-select: none;
                /* Safari */
                -ms-user-select: none;
                /* IE 10 and IE 11 */
                user-select: none;
                /* Standard syntax */
            }
        }
    }

    .amount-row {
        display: flex;
        align-items: center;
        margin-top: 2em;

        div {
            margin-right: 10em;

            p {
                color: var(--text-heading);
                font-size: 1.1em;
            }

            span {
                color: var(--defaultWhite);
                font-size: 1.7em;
            }
        }
    }
}

// //////////////////

.detailHeader {
    margin-top: 10px;
    margin-left: 15px;
    min-height: 100px;

    .dfaIc {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;


    }

    .monthYear {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;
    }

    .text {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 10px;

    }

    .cartDiv {

        display: flex;
        flex-wrap: wrap;

        .cart {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            border: 1px solid grey;
            width: 200px;
            height: 100px;
            border-radius: 13px;
            margin-right: 15px;
            margin-bottom: 15px;

            .icon {
                padding: 0px 15px;
            }

            .textDiv {
                // border: 1px solid grey;
                flex: 1;
                margin-left: 12px;

                .text1 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .text2 {
                    color: #B9B8B8;
                    font-size: 14px;
                }
            }
        }

        .cartRemaining {
            border: 1px solid var(--red);

            p {
                color: var(--red) !important;
            }
        }
    }

    .headingTitleDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
    }


}