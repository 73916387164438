.addModuleKanban{
    background-color: var(--projectCardBg);
    margin: 5px;
    max-width: 320px;
    min-width: 320px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    overflow: hidden;
}

.addModuleKanbanInput{
    
}