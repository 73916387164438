.customSelectInputEod {
    background-color: yellow;
}

.css-1jq4jm1-MuiAutocomplete-root {
    height: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.css-1jq4jm1-MuiAutocomplete-root:hover {
    border: none !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input ::-moz-focus-inner {
    border: none !important;
}

.selectDiv {
    background-color: #363D68;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;

    .placeholder {
        color: gray;
        // color: white;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 7px;
    }

    .selectDivInput {
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 10px 7px;
    }

    .selectDivInput :focus {
        border: none;
    }


    .inputSelectList {
        background-color: #29304c;
        position: absolute;
        z-index: 10;
        width: 100%;
        left: 0px;
        top: 45px;

        .ulOfSelectList {
            list-style: none;
            // width: 221px;
            max-height: 200px;
            overflow: auto;

            li {
                padding: 10px;
            }

            li:hover {
                background-color: rgb(68, 65, 171)
            }

        }

    }



}