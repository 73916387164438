/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';

body {
    background: #0e0841;
    font-size: 1.3rem;
    font-family: 'Lato-Regular';
    overflow: auto !important;
}

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
}

@media (min-width: 600px) {
    body {
        font-size: 1.3rem;
    }
}

@media (min-width: 800px) {
    body {
        font-size: 1.4rem;
    }
}

@media (min-width: 1000px) {
    body {
        font-size: 1.5rem;
    }
}

@media (min-width: 1200px) {
    body {
        font-size: 1.6rem;
    }
}

/* font family */
/* @font-face {
  font-family: "robotoLight";
  src: local("Lato"), url(../assets/fonts/Roboto-Light.ttf) format("opentype");
}
@font-face {
  font-family: "roboto";
  src: local("Lato"), url(../assets/fonts/Roboto-Regular.ttf) format("opentype");
} */

/* @font-face {
  font-family: "Comfortaa-SemiBold";
  src: local("Comfortaa-SemiBold"),
    url(../assets/fonts/Comfortaa-SemiBold.ttf) format("truetype");
}

.ff__Comfortaa__SemiBold {
  font-family: "Comfortaa-SemiBold" !important;
}

@font-face {
  font-family: "Comfortaa-Medium";
  src: local("Comfortaa-Medium"),
    url(../assets/fonts/Comfortaa-Medium.ttf) format("truetype");
}

.ff__Comfortaa__Medium {
  font-family: "Comfortaa-Medium" !important;
}

@font-face {
  font-family: "Comfortaa-VariableFont_wght";
  src: local("Comfortaa-VariableFont_wght"),
    url(../assets/fonts/Comfortaa-VariableFont_wght.ttf) format("truetype");
}

.ff__Comfortaa_VariableFont_wght {
  font-family: "Comfortaa-VariableFont_wght" !important;
} */

@font-face {
    font-family: 'Lato-Italic';
    src: local('Lato-Italic'), url(../assets/fonts/Lato-Italic.ttf) format('truetype');
}

.ff_Lato_Italic {
    font-family: 'Lato-Italic' !important;
}

@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato-Bold'), url(../assets/fonts/Lato-Bold.ttf) format('truetype');
}

.ff_Lato_Bold {
    font-family: 'Lato-Bold' !important;
}

/* 
@font-face {
  font-family: "Lato-Regular";
  src: local("macan-regular"),
    url(../assets/fonts/macan-regular.ttf) format("truetype");
} */
@font-face {
    font-family: 'Raleway-Regular';
    src: local('Raleway-Regular'), url(../assets/fonts/Raleway-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway-SemiBold';
    src: local('Raleway-SemiBold'), url(../assets/fonts/Raleway-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway-ThinItalic';
    src: local('Raleway-ThinItalic'), url(../assets/fonts/Raleway-ThinItalic.ttf) format('truetype');
}

.ralewayThinItalic {
    font-family: 'Raleway-ThinItalic' !important;
}

.ralewaySemiBold {
    font-family: 'Raleway-SemiBold' !important;
}

@font-face {
    font-family: 'Lato-Regular';
    src: local('Lato-Regular'), url(../assets/fonts/Lato-Regular.ttf) format('truetype');
}

.ff_Lato_Regular {
    font-family: 'Lato-Regular' !important;
}

@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), url(../assets/fonts/Lato-Light.ttf) format('truetype');
}

.ff_Lato_Light {
    font-family: 'Lato-Light' !important;
}

.lineClamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.d_flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.alignCenter {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    display: flex;
}

.alignStart {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.justifyContent_center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justifyContent_between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justifyContent_evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
}

.justifyContent_end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justifyContent_start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justifyContent_around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.flexWrap {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flexColumn {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 !important;
    flex: 1 !important;
}

.widthAuto {
    width: auto !important;
}

/* Margin */
.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 10px !important;
}

.m-2 {
    margin: 20px !important;
}

.m-3 {
    margin: 30px !important;
}

.m-4 {
    margin: 40px !important;
}

.m-5 {
    margin: 50px !important;
}

.mx-3px {
    margin: 0 3px !important;
}

.mr-10 {
    margin: 0 10px 0 0 !important;
}

.mx-10px {
    margin: 0 10px !important;
}

.mx-1 {
    margin: 0px 10px !important;
}

.mx-2 {
    margin: 0px 20px !important;
}

.mx-3 {
    margin: 0px 30px !important;
}

.mx-4 {
    margin: 0px 40px !important;
}

.mx-5 {
    margin: 0px 50px !important;
}

.my-1 {
    margin: 10px 0px !important;
}

.my-2 {
    margin: 20px 0px !important;
}

.my-3 {
    margin: 30px 0px !important;
}

.my-4 {
    margin: 40px 0px !important;
}

.my-5 {
    margin: 50px 0px !important;
}

.mt-5px {
    margin-top: 5px;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.mt-5 {
    margin-top: 50px !important;
}

.mb-5px {
    margin-bottom: 5px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-4 {
    margin-bottom: 40px !important;
}

.mb-5 {
    margin-bottom: 50px !important;
}

.mb-05 {
    margin-bottom: 5px !important;
}

.mr-05 {
    margin-right: 5px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.mr-2 {
    margin-right: 20px !important;
}

.mr-3 {
    margin-right: 30px !important;
}

.mr-4 {
    margin-right: 40px !important;
}

.mr-5 {
    margin-right: 50px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml--1 {
    margin-left: -10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.ml-3 {
    margin-left: 30px !important;
}

.ml-4 {
    margin-left: 40px !important;
}

.ml-5 {
    margin-left: 50px !important;
}

/* Padding */
.p-0 {
    padding: 0px !important;
}

.p-05 {
    padding: 05px !important;
}

.p-1 {
    padding: 10px !important;
}

.p-2 {
    padding: 20px !important;
}

.p-3 {
    padding: 30px !important;
}

.p-4 {
    padding: 40px !important;
}

.p-5 {
    padding: 50px !important;
}

.p-10px {
    padding: 10px;
}

.px-1 {
    padding: 0px 10px !important;
}

.px-2 {
    padding: 0px 20px !important;
}

.px-3 {
    padding: 0px 30px !important;
}

.px-4 {
    padding: 0px 40px !important;
}

.px-5 {
    padding: 0px 50px !important;
}

.py-1 {
    padding: 10px 0px !important;
}

.py-2 {
    padding: 20px 0px !important;
}

.py-3 {
    padding: 30px 0px !important;
}

.py-4 {
    padding: 40px 0px !important;
}

.py-5 {
    padding: 50px 0px !important;
}

.pt-05 {
    padding-top: 02px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-1 {
    padding-top: 10px !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.pt-3 {
    padding-top: 30px !important;
}

.pt-4 {
    padding-top: 40px !important;
}

.pt-5 {
    padding-top: 50px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-1 {
    padding-bottom: 10px !important;
}

.pb-2 {
    padding-bottom: 20px !important;
}

.pb-3 {
    padding-bottom: 30px !important;
}

.pb-4 {
    padding-bottom: 40px !important;
}

.pb-5 {
    padding-bottom: 50px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 10px !important;
}

.pl-2 {
    padding-left: 20px !important;
}

.pl-3 {
    padding-left: 30px !important;
}

.pl-4 {
    padding-left: 40px !important;
}

.pl-5 {
    padding-left: 50px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pr-1_5 {
    padding-right: 15px !important;
}

.pr-2 {
    padding-right: 20px !important;
}

.pr-3 {
    padding-right: 30px !important;
}

.pr-4 {
    padding-right: 40px !important;
}

.pr-5 {
    padding-right: 50px !important;
}

.textCenter {
    text-align: center;
}

.borderBottom_0 {
    border-bottom: 0px !important;
}

.customRowComponent .customDatePicker__ {
    justify-content: flex-start !important;
}

/* .headerFont {
  font-size: 18px;
  font-family: roboto;
}

.rowHeader {
  font-size: 14px !important;
  font-family: robotoLight !important;
}

.normalFont {
  font-size: 13px !important;
  font-family: roboto !important;
}
.errorFont {
  font-size: 11px !important;
  font-family: robotoLight !important;
  color: #ff6347;
} */

.smallFont {
    font-size: 13px;
    font-family: 'Lato-Regular';
}

.border_solid {
    border: 1px solid var(--divider);
}

.border_solid_left {
    border-left: 1px solid var(--divider);
}

.border_solid_right {
    border-right: 1px solid var(--divider);
}

.border_solid_bottom {
    border-bottom: 1px solid var(--divider);
}

.border_solid_top {
    border-top: 1px solid var(--divider);
}

.cursorText {
    cursor: text;
}

.cursorPointer {
    cursor: pointer;
}

.cursorNotAllowed {
    cursor: not-allowed !important;
}

a {
    text-decoration: none;
}

.inheritParent {
    height: 100%;
    width: 100%;
}

.inherit {
    height: inherit;
    width: inherit;
}

.textEllipse {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.remove-text-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

:root {
    --defaultBlack: #000;
    --defaultWhite: #ffffff;
    --newBlue: #191b34;
    --logo: rgb(19, 19, 45);
    --newBlueLight: #29304c;
    --orange: #ee7700;
    --red: #ff4365;
    --skyBlue: #00adee;
    --gray: #9d9999;
    --lightGray: #737373;
    --lightSkyBlue: #e5f7fd;
    --pink: #ffc9c9;
    --lightPink: #fdf1e5;
    --green: #02cd79;
    /* --lightBlue: #669df5; */
    --lightBlue: #0098eb;
    --violet: #775cc3;
    --lightOrange: #dd8d0f;
    --black: #181b34;
    --paleBlack: #28304c;
    --blakish: #1f2640;
    --lightGray: #525e8b;
    --darkgray: #363847;
    --primary: #f29726;
    --divider: rgba(239, 239, 239, 0.1);
    --darkBackground: #172032;
    --projectCardBg: #2d3463;
    --progressBarColor: #625df5;
    --progressBarBgColor: #646991;
    --textColor: #8a9aff;
    --milestoneRowColor: #292f58;
    --milestoneRowAddColor: #353581;
    --milestoneRowElColor: #22274b;
    /* --chipGreen: #62fb76; */
    --chipGreen: #02cd79;
    --chipYellow: #fabc2a;
    --yellow: #ffb300;
    --chipBlue: #2d98eb;
    --chipRed: #ce253b;
    --chipOrange: #f46503;
    --chipPink: #f47ec8;
    --blackMirror: rgba(0, 0, 0, 0.507);
    /* --popUpColor: #4b4e69; */
    --popUpColor: #3a4067;
    --milestoneCard: #40487a;
    --borderGrey: #595b68;
    --checkBoxBlue: #1976d2;
    --lightPurple: #413b70;
    --listBgGrey: #4b4e69;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.divider {
    border-bottom: 1px solid var(--divider);
}

.commonHover:hover {
    background-color: var(--primary) !important;
    color: var(--defaultWhite) !important;
}

.MuiSelect-select {
    background-color: red;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    background-color: var(--lightOrange) !important;
    color: #fff;
}

.MuiListItem-button:hover {
    text-decoration: none;
    background-color: var(--lightBlue) !important;
    color: #fff !important;
}

/* .MuiListItem-button:hover >* {
color:#FFF !important
  } */
.MuiList-padding {
    padding: 0px !important;
}

.drawer_container {
    min-height: 100vh;
}

.drawer_container .MuiListItem-gutters {
    padding-left: 26px;
    padding-right: 16px;
}

.MuiInput-underline:before {
    border-bottom: 1px solid #fff !important;
}

.selectPopOver .MuiSelect-icon {
    color: #fff !important;
}

.selectPopOver svg.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOpen {
    color: var(--primary) !important;
}

.boxShadow {
    -webkit-box-shadow: 0px 2px 9px -1px rgb(0 0 0 / 17%), 0px 1px 12px 0px rgb(0 0 0 / 10%),
        0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    box-shadow: 0px 2px 9px -1px rgb(0 0 0 / 17%), 0px 1px 12px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.activeColor {
    background-color: var(--progressBarColor);
    color: var(--defaultWhite);
}

.activeIconColor {
    background-color: var(--progressBarColor) !important;
}

.activeIconColor > * {
    color: #fff !important;
}

.activeIconColor:hover {
    background-color: var(--primary) !important;
}

.inActiveIconColor:hover {
    background-color: var(--primary) !important;
}

.inActiveIconColor > * {
    color: var(--defaultWhite) !important;
}

.activeIconColor:hover > * {
    color: #fff !important;
}

.tui-image-editor-header-logo,
.tie-btn-history,
.tui-image-editor-header-buttons {
    display: none !important;
}

.imageEditorButtonStyle {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 2em 2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1em;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    -ms-flex-item-align: end;
    align-self: flex-end;
    /* right: 35px;
  bottom: 36px; */
    z-index: 9 !important;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    padding-bottom: 5px;
}

.firstDiv:first-child {
    height: inherit;
    width: inherit;
}

.customChipClass > * {
    margin: 0.15em 0;
}

.overflowVisible {
    overflow: visible !important;
    z-index: 99 !important;
    outline: none !important;
}

.MuiAutocomplete-popper {
    z-index: 9999 !important;
}

.MuiChip-avatarSmall {
    border-radius: 50% !important;
}

.MuiSkeleton-pulse,
.MuiSkeleton-wave {
    /* background-image: linear-gradient(to right, var(--lightBlue) , var(--milestoneRowAddColor)); */
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.filterBatch {
    border-radius: 50%;
    position: absolute;
    background: var(--milestoneRowAddColor);
    color: #fff;
    width: 15px;
    height: 16px;
    font-size: 11px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.clearFilter {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
}

.clearFilterDisable {
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
}

.closeButtonCustomRow {
    position: absolute;
    right: 5px;
    cursor: pointer;
    top: 5px;
    z-index: 5;
    background: var(--milestoneRowAddColor);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.closeButton {
    cursor: pointer;
    z-index: 5;
    background: var(--progressBarColor);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 13px;
}

.bugInfo .MuiPaper-root {
    /* top: 50% !important; */
    /* left: 33% !important; */
}

.titleInput {
    border: none;
    border-bottom: 1px solid var(--lightBlue);
    background-color: transparent;
}

.customCalender * {
    color: #fff;
}

.titleValue {
    font-size: 20px;
    font-family: 'Raleway-SemiBold';
}

.customCalender * {
    color: #fff;
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #625df5 !important;
}

.fabSpeedDialAction * {
    color: #fff;
}

.fabSpeedDialAction:hover {
    background-color: var(--lightBlue) !important;
}

body .completedRow {
    background: rgba(98, 93, 245, 0.4) !important;
}

.lineThrough {
    text-decoration: line-through;
}

body .completedRowSideLine {
    background-color: rgba(0, 146, 34, 1) !important;
}

.RSPBprogressBar .RSPBstep {
    right: -4% !important;
    left: unset !important;
}

.done-crazy-balls-status-animation {
    position: absolute;
    left: calc((100% - 139px) / 2);
    right: calc((100% - 139px) / 2);
    top: 0;
    bottom: 0;
    background-position: left center;
    background-size: calc(139px * 75);
    -webkit-animation: crazy-balls-status-animation-keyframe calc(75s / 25) steps(75);
    animation: crazy-balls-status-animation-keyframe calc(75s / 25) steps(75);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@-webkit-keyframes crazy-balls-status-animation-keyframe {
    0% {
        background-image: url('../assets/images/done_wincy_animation.png');
    }

    99.9% {
        background-image: url('../assets/images/done_wincy_animation.png');
        background-position: calc(100% * -75);
    }
}

@keyframes crazy-balls-status-animation-keyframe {
    0% {
        background-image: url('../assets/images/done_wincy_animation.png');
    }

    99.9% {
        background-image: url('../assets/images/done_wincy_animation.png');
        background-position: calc(100% * -75);
    }
}

.MuiInputBase-root {
    color: #fff !important;
}

.example {
    /* border-left: 1px solid #999999;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #999999; */
    /* border-radius: 10px; */
    /* margin: 0px; */
    /* padding: 20px; */
}

.item {
    font-family: sans-serif;
    border-top: 1px solid #ffffff;
    background-color: #eeeeee;
    border-bottom: 1px solid #999999;
    border-radius: 6px;
    margin-bottom: 4px;
    padding: 10px;
    padding-left: 8px;
    opacity: 1;
}

.item .title {
    font-weight: bold;
    font-size: 125%;
    margin: 0;
    margin-bottom: 0.3rem;
}

.item .body {
    margin: 0;
    margin-bottom: 0.3rem;
}

.item .small {
    font-size: 66%;
    text-align: right;
}

.dragged .item {
    opacity: 0.25;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #ffffff;
    color: #000;
}

.floating .item {
    background-color: #ffffff;
    -webkit-box-shadow: 0 4px 20px #666666;
    box-shadow: 0 4px 20px #666666;
    color: #000;
}

.dl-item.floating {
    width: 85%;
}

.checkedColor {
    color: green !important;
}

.rootHover:hover {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

.blink {
    font-family: cursive;
    -webkit-animation: blink 1.5s linear infinite;
    animation: blink 1.5s linear infinite;
}

@-webkit-keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.filterContainer {
    /* display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px; */
    display: flex;
    flex-wrap: wrap;
}

.filterContainer .filterChildContainer {
    /* width: 150px; */
    margin-right: 15px;
}

.filter_title {
    /* margin-bottom: 0px; */
    line-height: 0px;
}

.MuiInputBase-input {
    overflow: hidden !important;
}

/* jodit styles */

.jodit-popup__content {
    background: var(--darkBackground) !important;
    color: #fff;
}

.jodit-popup__content button {
    color: #fff;
}

.jodit-popup__content button:hover {
    color: #000 !important;
}

.jodit-ui-input__wrapper {
    background: none !important;
}

.jodit-ui-input__wrapper input {
    background: none !important;
}

.jodit-ui-checkbox__wrapper {
    /* display: none !important; */
    /* opacity: 0; */
}

.jodit-ui-checkbox {
    color: #fff;
}

.jodit-ui-checkbox div {
    margin-top: -25px;
}

.jodit-ui-checkbox_focused_true {
    /* border: 1px solid #00adee !important; */
    color: var(--primary);
    /* border: 1px solid var(--chipBlue); */
}

.jodit-ui-checkbox_focused_false {
    color: #fff;
}

.jodit-ui-input__wrapper input {
    /* color: #000; */
}

.jodit-toolbar-collection,
.jodit-toolbar-editor-collection {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.jodit-container:not(.jodit_inline) {
    background: none !important;
    border: 1px solid var(--divider) !important;
    color: #fff !important;
}
.jodit-container:not(.jodit_inline) {
    background: none !important;
    border: 1px solid var(--divider) !important;
    color: #000 !important;
}

.jodit-wysiwyg li {
    margin-left: 1em !important;
}

/* logo animation */

.logoAnime {
    overflow: visible;
}

.logoAnime #incy {
    animation: left-to-right 1s linear;
    /* animation-delay: 10s; */
}

.logoAnime #yellowRight {
    /* animation: left-to-right 1s linear; */

    /* fill: #f9bc28; */
    fill-opacity: 0.5;
    stroke-dasharray: 550;
    stroke-dashoffset: 1000;
    stroke-width: 20px;
    stroke-linecap: round;
    animation: dash 5s linear;
    /* background-color: #f9bc28; */
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear alternate infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes left-to-right {
    0% {
        transform: translateX(150px);
        opacity: 0;
    }

    90% {
        opacity: 0.7;
        /* transform: translateX(-50px); */
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes checkmark {
    0% {
        fill-opacity: 0;
    }

    /* 20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  } */
    100% {
        fill-opacity: 1;
    }
}

/* --------------------------------------------- */

.inEar001 .MuiOutlinedInput-root {
    padding: 0px !important;
}

.inEar001 .MuiAutocomplete-input {
    padding: 7.5px 62px 7.5px 6px !important;
}

.inEar001 {
    color: #fff !important;
    border: 1px solid;
    border-color: var(--defaultWhite);
    border-radius: 4px;
}

.inEar001 .MuiAutocomplete-tag {
    color: #fff;
}

.inEar001 svg {
    color: #fff !important;
}

.inEar001 .MuiAutocomplete-inputRoot {
    min-width: 160px !important;
}

/* Custom Select Oulined Design */

.selectOutlined .MuiInput-formControl {
    width: 100%;
    height: 44px;
    padding-left: 5px;
    border-radius: 4px;
    background: #363d68;
    /* border: 1px solid var(--divider); */
}

.selectOutlined .MuiSelect-icon {
    color: #fff !important;
}

.selectOutlined .MuiInput-underline:before {
    transform: scaleX(0);
}

.selectOutlined .MuiInput-underline.Mui-focused:after {
    transform: scaleX(0);
}

.selectOutlined .MuiInput-underline.Mui-error:after {
    transform: scaleX(0);
}

textarea {
    resize: none;
}
