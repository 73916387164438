.addRequestBox {
  color: white;

  .addRequestBox_headingText {
    font-size: 16px;
    font-family: "Lato-Bold";
  }
  .addRequestBox_calender {
    width: 100%;
    .rmdp-top-class {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rmdp-calendar {
      width: 100%;
    }

    .rmdp-day-picker {
      div {
        width: 100%;
      }
    }
  }

  .addRequestBox_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 15px;

    .headerText {
      // padding: 40px 10px;
      font-family: "Lato";
      font-size: 20px;
    }

    .icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  .line {
    border-top: 1px solid var(--borderGrey);
    width: 100%;
    height: 1px;
  }

  .addRequestSection {
    padding: 15px;

    .flexEnd {
      margin-top: 40px;
      display: flex;
      align-items: end;
      justify-content: flex-end;
    }

    .calenderInSchedule {
    }

    .dateDiv {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      flex-wrap: wrap;

      .datesList {
        padding: 5px 10px;
        background-color: var(--lightPurple);
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .mh {
    margin: 35px 0px 20px 0px;
  }

  .df {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputBox {
    width: 200px;
    height: 50px;
    border-radius: 5px;
    padding-left: 10px;
    background: #413b70;

    .dateInput {
      background: transparent;
      color: var(--primary);
      border: none;
    }
  }

  .inputHourDiv {
    display: flex;
    align-items: center;
    width: 200px;
    height: 50px;
    border-radius: 5px;
    // padding-left: 10px;
    background: #413b70;

    .inputHour {
      max-width: 17px !important;
      background: transparent;
      color: var(--primary);
      border: none;
      margin-left: 10px;
      border-bottom: 1px solid var(--borderGrey);
      height: fit-content;
    }
  }
}

.rmdp-shadow {
  box-shadow: none;
  border: none !important;
  background: transparent;
}

.rmdp-day,
.rmdp-week-day {
  color: #000;
  cursor: pointer;
  height: 40px;
  position: relative;
  width: 60px;
  color: white;
}

.rmdp-day span {
  border-radius: 0px;
  bottom: 3px;
  font-size: 14px;
  left: 3px;
  position: absolute;
  right: 3px;
  top: 3px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #625df5;
  box-shadow: none;
  color: #fff;
}

.rmdp-day.rmdp-today span {
  background-color: var(--primary);
  color: #fff;
}

.rmdp-header-values {
  color: white;
  margin: auto;
}

.rmdp-month-picker,
.rmdp-year-picker {
  background-color: #151148;
  border-radius: 0 0 5px 5px;
  bottom: 2px;
  left: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
}
