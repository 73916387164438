.EodFilterContainer {
    // margin-bottom: 40px;

    .filterHeader {
        background-color: #625df5;
        padding: 10px 15px;
        border-radius: 5px 5px 0px 0px;
        font-size: 16px;
    }

    .filterBody {
        padding: 10px 15px;
        background: var(--blakish);
        border-radius: 0px 0px 5px 5px;

        .filterBodyRow {
            // background: var(--blakish);
            display: flex;
            padding: 5px;
            align-items: center;
            cursor: pointer;


            .eodCheckBox {
                border: 1px solid white;
                margin-right: 10px;
                display: flex;
                // padding: 3px;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                width: 15px;
                height: 15px;
            }
        }

        .filterBodyRow:hover {
            background: var(--primary);
        }

        .afterSelect {
            background: var(--lightGray);
            border-radius: 5px;
            margin: 10px 0px;
            padding: 10px;
            color: rgb(170, 170, 170);
        }

        .calenderFilter {

            .react-calendar {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: gray;
                background: var(--blakish);
                border: none;
                width: 100%;
            }

            .react-calendar__navigation button {
                min-width: 44px;
                background: none;
            }

            .react-calendar__month-view__weekdays__weekday {
                text-align: center;
                margin: 10px 0px;
                background: var(--blakish);
            }


            .react-calendar__month-view__days__day {
                background: var(--blakish);
                color: white;
                border: 0 !important;
                padding: 10px;
            }

            .react-calendar__tile:enabled:hover {
                background-color: var(--lightGray);
            }

            .react-calendar__tile:enabled:focus {
                background-color: var(--primary);
                ;
            }

            .react-calendar__year-view__months__month {
                background: var(--blakish);
                color: white;
                border: 0 !important;
                margin-bottom: 20px;
            }

            .react-calendar__decade-view__years__year {
                background: var(--blakish);
                color: white;
                border: 0 !important;
                margin-bottom: 20px;
            }

            .react-calendar__century-view__decades__decade {
                background: var(--blakish);
                color: white;
                border: 0 !important;
                margin-bottom: 20px;
            }

            .react-calendar__navigation {
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
            }
        }

        //all in one 
        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next2-button,
        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__next-button {
            background: var(--blakish);
            color: white;
            border: 0 !important;
            font-size: 35px;
            padding: 0px 5px;
        }


        .react-calendar__navigation__label {
            background: var(--blakish);
            color: white;
            border: 0 !important;
            font-size: 25px;
            flex-grow: 0 !important;
        }


        .react-calendar__viewContainer {
            width: 100%;
        }

        .doneBtnContainer {
            display: flex;
            justify-content: flex-end;
            margin: 20px 5px;

            .doneBtn {

                background: #625df5;
                border: 0px;
                padding: 5px 20px;
                border-radius: 25px;
                color: white;
                cursor: pointer;
                margin-left: 10px;
            }

            // .doneBtn:active {
            //     padding: 8px 16px;
            // }

        }

    }
}