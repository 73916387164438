.eodDateRange {

    //picker background color
    background-color: var(--blakish);

    .rdrDateDisplayWrapper {
        background: var(--popUpColor);
    }

    //select styling
    .rdrMonthAndYearPickers select {
        color: white !important;
        font-size: 17px;
        background: var(--blakish);
        border: 0px;

    }

    // .rdrMonthAndYearPickers select:hover {
    //     background-color: rgba(0, 0, 0, 0.07);
    //     color: yellow;
    // }


    //picker text color
    .rdrDayNumber span {
        /* color: #1d2429; */
        color: white !important;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
        color: white !important;
    }

    .rdrDayPassive .rdrDayNumber span {
        color: #969393 !important;
    }

    //input hide
    .rdrDateDisplayWrapper {
        display: none !important;
    }
}