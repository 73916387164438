.iconContainer {
  display: flex;
  align-items: center;
  min-width: 100px;

  .time_value {
    color: "#FFF";
    flex: 1;
  }

  .time_placeholder {
    flex: 1;
    color: var(--gray);
  }
}

.input_time_picker_container {
  height: 40px !important;
  width: 100% !important;
  background-color: #413b70;
  padding: 0px 10px;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  border-radius: 6px;
}
.time_picker_container {
  background-color: var(--milestoneCard);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 14px;

  div input {
    background-color: transparent;
    cursor: pointer;
  }

  .rmdp-am {
    color: #fff !important;
  }
}
.arrow_time_picker {
  top: 1px !important;
  &::after {
    background-color: var(--milestoneCard);
    box-shadow: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  &[direction="top"] {
    border-bottom: 1px solid var(--milestoneCard);
  }
}

// .arrow_time_picker[direction="top"] {
//   border-bottom: 1px solid var(--milestoneCard);
//   // box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
//   //   rgba(0, 0, 0, 0.22) 0px 10px 10px;
// }

.input_time_picker {
  background: #413b70;
  border: none;
  height: 100%;
  width: 100%;
}
