.linkClientsBox {
    padding: 20px;
    width: 100%;
    overflow: auto;

    .textHead {
        font-weight: 700;
        font-size: 35px;
    }

    .headingLinkClient {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
    }
}