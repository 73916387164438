.tableSection {
  // display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;

  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 10px 0px; */
  .eodText {
    border-right: 1px solid var(--newBlueLight);
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-transform: capitalize;
  }
  .eodDescription_read_more {
    background: var(--projectCardBg);
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 12px;
    align-self: baseline;
    margin: 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }

  .eodTextIcon {
    display: flex;
    text-align: center;
    border-right: 1px solid var(--newBlueLight);
    padding: 10px 5px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .tableSection div {
    width: 20%;
  }

  .eodDescription {
    text-align: start;
    // width: 100%;
    border-right: 1px solid var(--newBlueLight);
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
  }

  .list {
    padding-left: 10px;
    margin-bottom: 5px;
  }

  .formText {
    color: gray;
    cursor: pointer;
  }
}

.textGray {
  color: #a7a6a1 !important;
}
