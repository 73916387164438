/* team Screen */

.myteam {
  margin: 10px;
  font-size: 0.8em;
  /* height: 110vh; */
  position: relative !important;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}

.iconWrapper {
  background-color: var(--progressBarColor);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

/* table Area */

.tableArea {
  margin: 1em !important;
}

/* table head global */
.tableHeadLessInfo,
.tableHead,
.tableHeadIn {
  border: 1px solid var(--divider);
  display: none;
  padding: 1em 0;
}
.tableHeadLessInfo>*,
.tableHead > *,
.tableHeadIn > * {
  margin-left: 1em;
}

/* table elements */
.tableElLessInfo,
.tableEl,
.tableElIn {
  position: relative;
  border: 1px solid var(--divider);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: 100%;
  height: auto;
}

.tableElHead {
  border: 1px solid var(--divider);
  height: 100%;
  display: flex;
  padding: 1em;
  flex-direction: column;
  position: relative;
}
.tableElLessInfo>*,
.tableEl > *,
.tableElIn > *,
.tableElHead > * {
  margin-left: 1em;
}

.side {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--primary);
  height: 100%;
  cursor: pointer;
  transition: width 0.2s ease-in;
  width: 4px !important;
  margin: 0;
}

.tableElLessInfo:hover .side,
.tableEl:hover .side {
  width: 7px !important;
  transition: width 0.2s ease-in;
}
.tableElIn:hover .side {
  width: 7px !important;
  transition: width 0.2s ease-in;
}

.elIcon {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px !important;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.elIcon > * {
  margin-right: 10px;
}

.editData {
  display: flex;
  align-items: center;
}

.editData input {
  border: none !important;
  outline: none !important;
}

.bankDetails {
  display: block;
  position: absolute;
  right: 0;
  top: 30px;
  width: auto;
  height: auto;
  padding: 1em;
  z-index: 10;
  background-color: rgba(10, 82, 177, 0.849);
  background-color: var(--lightBlue);
  backdrop-filter: blur(1px);
}

.name {
  display: flex;
  align-items: center;
}

.side:hover {
  cursor: pointer;
}

.btnArea {
  display: flex;
  align-items: center;
}

.btnArea > * {
  margin-right: 1em;
}

@media (min-width: 700px) {
  .myteam {
    font-size: 0.8em;
  }

  .tableHead {
    display: grid;
    grid-template-columns: 1.5fr 1.8fr 0.8fr 0.9fr 0.8fr 1px;
    background-color: var(--milestoneRowColor);
    /* padding-left: 5em !important; */
    /* text-align: left; */
  }
  .tableHeadLessInfo {
    display: grid;
    grid-template-columns: 1.5fr 1.8fr 0.8fr 0.9fr 1px;
    background-color: var(--milestoneRowColor);
    /* padding-left: 5em !important; */
    /* text-align: left; */
  }
  .tableHeadIn {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1fr 0.6fr;
    background-color: var(--milestoneRowColor);
  }
  .tableElLessInfo{
    display: grid;
    grid-template-columns: 1.5fr 1.8fr 0.8fr 0.9fr 1px;
    align-items: center;
    padding: 0;
    align-items: center;
    height: 35px;
  }
  .tableEl {
    display: grid;
    grid-template-columns: 1.5fr 1.8fr 0.8fr 0.9fr 0.8fr 1px;
    align-items: center;
    padding: 0;
    align-items: center;
    height: 35px;
  }
  .tableElIn {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1fr 0.6fr;
    padding: 0;
    align-items: center;
    height: 35px;
  }
  .tableElHead {
    display: none;
  }
  .elIcon {
    position: relative;
  }
}

@media (min-width: 1100px) {
  .myteam {
    font-size: 1em;
  }
}

.loadingIcon {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
