.eodFormDiv {
  padding: 20px;
  color: white;

  .section1 {
    display: flex;
    justify-content: space-between;
  }
  .remove_child_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .remove_child {
      display: flex;
      border-radius: 50%;
      overflow: hidden;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      background: var(--red);
      font-size: 12px;
      cursor: pointer;
    }
  }

  .form {
    margin: 20px 0px;

    .mainChildDiv {
      margin-bottom: 25px;
      border-color: gray;
      border-bottom-style: solid;
      border-width: 1px;
    }

    .formTopRow {
      display: flex;
      justify-content: space-between;

      .topRow {
        width: 32%;
      }
    }

    .saveBtn {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .mb {
    margin-bottom: 5px;
  }

  .btnOfForm {
    display: flex;
    justify-content: flex-end;
  }
}
