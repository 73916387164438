.newClientDiv {
    padding: 20px 0px;

    .row1 {
        display: flex;
        align-items: center;

        .inputWithLevel {
            width: 50%;
            margin-bottom: 20px;
            margin-right: 10px;
            height: 80px;
            // .text {
            //     color: rgba(102, 107, 138, 1);
            // }
        }

    }

    .text {
        color: rgba(102, 107, 138, 1);
    }

    .btnDiv {
        margin: 20px 10px;
        display: flex;
        justify-content: flex-end;
    }
}