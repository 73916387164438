.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border);
    margin: 1em;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    padding: 0 1em;
    width: 98% !important;

    &:hover {
        opacity: 0.9;
        background: var(--box-color-opacity);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 1px solid var(--border);
        transition: background 0.3s ease-in;
    }

    .dateArea {
        display: flex;
        align-items: center;
    }

    .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        padding-right: 3em;

        .text {
            width: 25%;
        }
    }

    .btnDiv {
        display: flex;

        .button {
            width: 5em;
            height: 1.8em;
            overflow-wrap: anywhere;
            padding: 14px 7px;

            span {
                font-size: 0.7em;
            }
        }
    }

    .downloadDiv {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.boxIn {
    grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr !important;
}

.box {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr;
    margin: 0px 20px;
    border: 1px solid gray;
    min-height: 50px;
    padding: 10px;
    max-width: 1025px;

    .box2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
    }

    .dfc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
    }

    .textBlue {
        color: rgba(0, 133, 255, 1);
        text-decoration: underline;
        font-weight: 500;
        font-size: 13;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .textRed {
        color: rgba(255, 70, 103, 1);
        font-size: 11px;
        margin-top: 3px;
    }
}

.pdfViewer {
    // background-color: rgb(45, 52, 99);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
}

.headerIn {
    grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr !important;
}

.header {
    background-color: rgba(45, 52, 99, 1);
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr;
    margin: 0px 20px;
    // border: 1px solid gray;
    min-height: 50px;
    padding: 10px;
    max-width: 1025px;
    // border-radius: 4px, 4px, 0px, 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .dfs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .dfc {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ml {
        margin-left: 20px;
    }
}

.fdc {
    display: flex;
    flex-direction: column;
}
