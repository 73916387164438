.forgotPassword {
  display: flex;
  flex-direction: column;
}

.inputArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
}

.inputArea > * {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .forgot_wrap {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .forgot_wrap > .forgot_wrap__left {
    width: 100%;
    height: 100vh;
    background: var(--mainBlue);
    /* padding: 3rem; */
  }
  .forgot_wrap > .forgot_wrap__right {
    display: none;
  }
  .forgot_wrap > .forgot_wrap__left > .left_position-relative > .form-wrapper {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 2.5%;
    width: 95%;
    background: white;
    text-align: center;
    /* padding: 4rem 4rem; */
    padding: 1rem 0.5rem;
    border: solid 1px rgb(204, 204, 204);
    height: 72vh;
    overflow-y: auto;

    border-radius: 1rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form {
    margin-top: 1.5rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap {
    margin-bottom: 1.1rem;
  }
  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > p {
    margin-left: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > input {
    border-radius: 20px;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > * {
    margin-bottom: 0.5rem;
  }
  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > h4 {
    color: var(--mainBlue);
    font-size: 1.6rem;
    font-weight: 400;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    p {
    color: #2f2e2e;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > img {
    margin-bottom: 2rem;
    width: 5rem;
    height: 5rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer {
    margin-top: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer
    > p {
    font-size: 0.9rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer
    > p
    > span
    > a {
    color: var(--mainBlue);
    padding-bottom: 1px;
    border-bottom: 2px solid var(--mainBlue);
  }

  input::placeholder {
    font-size: 0.8rem;
    /* line-height: 4; */
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > .forgotpassword {
    text-align: right;
    font-size: 0.8rem;
    margin-top: 0.7rem;
  }

  .forgot_wrap__right {
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* =======================  Responsiveness Issue =============================== */

@media only screen and (min-width: 600px) and (max-width: 1500px) {
  .forgot_wrap {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .forgot_wrap > .forgot_wrap__left {
    width: 28%;
    height: 100vh;
    background: var(--mainBlue);
    /* padding: 3rem; */
  }

  .forgot_wrap > .forgot_wrap__left > .left_position-relative > .form-wrapper {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 8%;
    width: 28%;
    background: white;
    text-align: center;
    /* padding: 4rem 4rem; */
    padding: 3rem 1.5rem;
    border: solid 1px rgb(204, 204, 204);
    height: 72vh;
    overflow-y: auto;

    border-radius: 1rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form {
    margin-top: 1.5rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap {
    margin-bottom: 1.1rem;
  }
  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > p {
    margin-left: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > input {
    border-radius: 20px;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > * {
    margin-bottom: 0.5rem;
  }
  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > h4 {
    color: var(--mainBlue);
    font-size: 1.6rem;
    font-weight: 400;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    p {
    color: #2f2e2e;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > img {
    margin-bottom: 2rem;
    width: 4rem;
    height: 4rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer {
    margin-top: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer
    > p {
    font-size: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer
    > p
    > span
    > a {
    color: var(--mainBlue);
    padding-bottom: 1px;
    border-bottom: 2px solid var(--mainBlue);
  }

  .forgot_wrap > .forgot_wrap__right {
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input::placeholder {
    font-size: 0.8rem;
    /* line-height: 4; */
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > .forgotpassword {
    text-align: right;
    font-size: 0.8rem;
    margin-top: 0.7rem;
  }

  .forgot_wrap__right {
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* =============================== Responsive ================================= */
@media screen and (min-width: 1501px) {
  .forgot_wrap {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .forgot_wrap > .forgot_wrap__left {
    width: 28%;
    height: 100vh;
    background: var(--mainBlue);
    /* padding: 3rem; */
  }

  .forgot_wrap > .forgot_wrap__left > .left_position-relative > .form-wrapper {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 8%;
    width: 28%;
    background: white;
    text-align: center;
    /* padding: 4rem 4rem; */
    padding: 3rem 1.5rem;
    border: solid 1px rgb(204, 204, 204);
    height: 72vh;
    overflow-y: auto;

    border-radius: 1rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form {
    margin-top: 1.5rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap {
    margin-bottom: 1.1rem;
  }
  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > p {
    margin-left: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > input {
    border-radius: 20px;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > * {
    margin-bottom: 0.5rem;
  }
  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > h4 {
    color: var(--mainBlue);
    font-size: 1.6rem;
    font-weight: 400;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    p {
    color: #2f2e2e;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-header
    > img {
    margin-bottom: 2rem;
    width: 4rem;
    height: 4rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer {
    margin-top: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer
    > p {
    font-size: 0.8rem;
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > .form-footer
    > p
    > span
    > a {
    color: var(--mainBlue);
    padding-bottom: 1px;
    border-bottom: 2px solid var(--mainBlue);
  }

  .forgot_wrap > .forgot_wrap__right {
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input::placeholder {
    font-size: 0.8rem;
    /* line-height: 4; */
  }

  .forgot_wrap
    > .forgot_wrap__left
    > .left_position-relative
    > .form-wrapper
    > form
    > .input-wrap
    > .forgotpassword {
    text-align: right;
    font-size: 0.8rem;
    margin-top: 0.7rem;
  }

  .forgot_wrap__right {
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loginForm p {
  color: #afb0bb;
  /* text-align: center; */
  width: 70%;
}
