.filterList {
    .afterSelect {
        background: var(--lightGray);
        border-radius: 5px;
        margin: 10px 0px;
        padding: 10px;
        color: rgb(170, 170, 170);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filterInput {
            background: transparent;
            border: none;
        }

        .filterInput:focus {
            border: none;
        }

        ::placeholder {
            color: rgb(155, 152, 152);
        }

        .filterBtn {
            display: flex;
            align-items: center;
            min-width: 50px;

            p {
                padding: 3px 10px;
                background: rgb(98, 93, 245);
                border-radius: 20px;
                font-size: 10px;
                margin-left: 8px;
                cursor: pointer;
                box-shadow: -1px 1px 4px 3px rgba(91, 161, 139, 0.33);
                color: white;
            }
        }
    }

    .filterLoading {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .filterBodyRow {
        display: flex;
        padding: 5px;
        align-items: center;
        cursor: pointer;

        .eodCheckBox {
            border: 1px solid white;
            margin-right: 10px;
            display: flex;
            // padding: 3px;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            width: 15px;
            height: 15px;
        }
    }
}